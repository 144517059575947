import { useContext, useState } from 'react';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import { AppConstants } from '../../../context/configs/constants';
import { useRef } from 'react';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [logando, setLogando] = useState(false);
  const [mensagem, setMensagem] = useState('');

  const {BackUserFunctions} = useContext(AppContext);

  

  const handleClick = () => {
    setLogando(true);
    BackUserFunctions.login(email, password).then(resp => {
      if (resp.authenticate) {
        navigate('/dashboard', { replace: true });
        setLogando(false);
      } else {
        setPassword('');
        setMensagem('Email ou Senha Inválidos');
        setLogando(false);

      }
    });
    
  };

  
  const setFirstPassword = (pass) => {
    if (pass.valueOf() === "senha") {
      setPassword("")
    }
  }

  return (

      <>
        <Stack spacing={3}>
          <TextField  name="email" label="Email do Usuário" value={email} onChange={(e) => setEmail(e.target.value)} />

          <TextField
            name="password"
            label="Senha"
            value={password} 
            onFocus={(e) => setFirstPassword(e.target.value)}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton 
                  onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
          {mensagem}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="right" sx={{ my: 2 }}>
          <Link variant="subtitle2" underline="hover" href={AppConstants.routePaths.resetPassword}>
            Esqueceu a senha?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}  loading={logando} loadingIndicator="Entrando…">
          Entrar 
        </LoadingButton >

        <Stack direction="row" alignItems="center" justifyContent="right" sx={{ my: 1 }}>
        </Stack>
      </>
  );
}
