import React from 'react'

export default function Homolog() {
    const leadster = (a,b,c,d) => {
        try{
            var e=b.head||b.getElementsByTagName("head")[0];
            var f=b.createElement("script");
            f.setAttribute("src",c);
            f.setAttribute("charset","UTF-8");
            f.defer=true;a.neuroleadId=d;e.appendChild(f)
        }catch(g){

        }
    }
  return (
    <div>
        <script>
            {leadster(window,document,"https://cdn.leadster.com.br/neurolead/neurolead.min.js","fKt1LhQujdYZKMwkcK0IcBAd4")}
        </script>         

    </div>
  )
}
