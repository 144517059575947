// components
import { Container } from '@mui/material';
import ContainerPage from './ContainerPage';
import StyledContent from '../components/styledContent';
// sections



export default function ReportsPage() {

  return (
    <ContainerPage title="Dashboard">
        <Container maxWidth="sm">
          <StyledContent>
            Relatórios
          </StyledContent>
        </Container>
    </ContainerPage>
  );
}
