import axios from 'axios';

var adicionalHeaders = [];
//adicionalHeaders['Origin'] = document.location.protocol.concat("//", document.location.host);

export const findChannelsTotal = async ({user, dataInicial, dataFinal}) => {
	const params = {
		user, dataInicial, dataFinal
	};
    
    console.log({params});

	return axios.post('findchannelstotal', { ...params }, {headers: {...adicionalHeaders}}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};

