import { CircularProgress, Grid } from "@mui/material";

export default function Loading() {
    return (
        <Grid style={{height: "100%"}} container direction="row" justifyContent="center" alignItems="center" >
            <Grid item>
                <CircularProgress />
            </Grid>
        </Grid>
    );

}
