import {  ExpandMore } from '@mui/icons-material';
import { DateTimePicker } from "@mui/x-date-pickers";
import { Accordion, AccordionDetails, AccordionSummary,  Box,  Button, Card, CardContent, 
  Checkbox, 
  CircularProgress, Container, FormControlLabel, Grid, MenuItem, Select, TextField, Typography} from '@mui/material';
import { AppContext } from 'context/AppContext';
import { useContext, useEffect, useState } from 'react';
import { InitialApoliceCaracteristicasGerais, InitialApoliceRegapHeader, InitialCobertura, InitialCoberturaAdicional, InitialCorretor, InitialEndosso, InitialObjeto, InitialPessoa,  InitialPremio,  InitialPremios } from 'context/configs/constants';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
/**
import {  makeStyles } from '@mui/material/s';
// sections


const useStylesTextField = makeStyles((theme) => ({
  root: { 
    "& .MuiFilledInput-root": {
      background: "rgb(232, 241, 250)"
    }
  }
}));
 */
function converterStringParaNumero(valor) {
  // Remove caracteres que não são números, vírgulas ou pontos
  var num = valor.replace(/[^\d,.]/g, '');
  
  // Verifica se a string contém vírgula
  if (num.includes(',')) {
      // Substitui vírgula por ponto
      num = num.replace(',', '.');
  }
  
  // Converte a string para número
  let numero = num;
  
  // Retorna o número
  return numero;
}

function removerCaracteresNaoNumericos(str) {
  // Expressão regular que identifica qualquer caractere que não seja número
  const regex = /[^0-9]/g;

  // Retorna a string com os caracteres não numéricos removidos
  return str.replace(regex, "");
}

export default function CreateApolicePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  //const classesTF = useStylesTextField();

  const {BackAppFunctions} = useContext(AppContext);


  const [arquivo, setArquivo] = useState("");
  const [loading, setLoading] = useState(true);
  const [titulo, setTitulo] = useState("Criar Apólice");
  const dataAtual = dayjs().add(0, 'day');
  const [headerValues, setHeaderValues] = useState(InitialApoliceRegapHeader);
  const [caracteristicasValues, setCaracteristicasValues] = useState(InitialApoliceCaracteristicasGerais);
  const [tomadorValues, setTomadorValues] = useState(InitialPessoa);
  const [seguradoValues, setSeguradoValues] = useState(InitialPessoa);
  const [coberturaAdicionalValues, setCoberturaAdicionalValues] = useState(InitialCoberturaAdicional);
  const [coberturaValues, setCoberturaValues] = useState(InitialCobertura);
  //const [premioValues, setPremioValues] = useState(InitialPremio);
  const [premiosValues, setPremiosValues] = useState(InitialPremios);
  const [objetoValues, setObjetoValues] = useState(InitialObjeto);
  const [listCorretores, setListCorretores] = useState(InitialCorretor);
  const [endossoValues, setEndossoValues] = useState(InitialEndosso);
  const [urlApolice, setUrlApolice] = useState("");
  const [clone, setClone] = useState(false);
  const codigoCoberturaRef = useRef(null);
  const codigoTipoIndossoRef = useRef(null);
  const [opcoesCoberturasAdicionais, setOpcoesCoberturasAdicionais] = useState([]);
  const [coberturasAdicionais, setCoberturasAdicionais] = useState([]);

  const onSetPremiosValues = (premios) => {
    const updatedPremios = premios.recalcularValorParcelas(premios);
    setPremiosValues(updatedPremios);
  }

  const makeItemAdicionalTela = (index, codigo, descricao) => {
    return {
      index, 
      descricaoTipoIdenizacao: "Adicional",
      codigoInterno: codigo, 
      checked: false, 
      descricaoBaseIdenizacao: descricao, 
      valorPremioAdicional: "" };
  }

  const adicionaisPadrao = [
    makeItemAdicionalTela(0, 99, "Outras"),
  ];

  const adicionaisPrestacaoServicos = [
    makeItemAdicionalTela(0, 100, "Trabalhistas e Previdenciário"),
    makeItemAdicionalTela(0, 101, "Fiscal e Tributário"),
    makeItemAdicionalTela(1, 102, "Multas"),
    makeItemAdicionalTela(2, 199, "Outro "),
  ];

  const adicionaisGarantiasPagamentos = [
    makeItemAdicionalTela(0, 200, "Garantias de Pagamento"),
  ];

  const adicionaisLocaticios = [
    makeItemAdicionalTela(0, 300, "IPTU"),
    makeItemAdicionalTela(1, 301, "Condomínio"),
    makeItemAdicionalTela(2, 302, "Água"),
    makeItemAdicionalTela(3, 303, "Luz"),
    makeItemAdicionalTela(3, 303, "Gás"),
    makeItemAdicionalTela(4, 399, "Outros"),
  ]

  const updateCheckAdicional = (itemCobertura, checked) => {
    var item = JSON.parse(JSON.stringify(itemCobertura));
    updateFieldAdicional(item, 'checked', checked);
    if (checked) {
      if (parseFloat("0".concat(item.valorPremioAdicional)) <= 0) {
        const premioBase = parseFloat(coberturaValues.cobertura.premioCobertura);
        var newPremio = premioBase * 17/100;
        item.valorPremioAdicional = newPremio.toLocaleString('en-US', {maximumFractionDigits : 2}).replace(",", "");
        updateFieldAdicional(item, 'valorPremioAdicional', item.valorPremioAdicional);
      }
    }
  }

  

  const updateFieldAdicional = (itemCobertura, fieldName, valor) => {
    console.log({itemCobertura, fieldName, valor});
    
    //listando todos os itens de opções de coberturas adicionais
    var lista = JSON.parse(JSON.stringify(opcoesCoberturasAdicionais));

    //varrendo lista para encontrar o item específico para ser alterado
    lista.map((item, index) => {
      //Se o item da lista for o item a ser atualizado...
      if (item) {
        if (item.codigoInterno) {
          if (item.codigoInterno.toString().toUpperCase().endsWith(itemCobertura.codigoInterno.toString().toUpperCase())) {
            lista[index][fieldName] = valor;
            setOpcoesCoberturasAdicionais(lista);
            updateItemCoberturasAdicionais(lista[index]);
          }
          return true;
        } else {
          return true;
        }
      } else {
        return true;
      }
    })
    

  }

  const updateItemCoberturasAdicionais = (itemCobertura) => {
    var lista = JSON.parse(JSON.stringify(coberturasAdicionais));

    console.log({itemCobertura, lista});

    if (itemCobertura.checked) {
      var atualizou = false;
      lista.map((item, index) => {
        // se o item já existe na cobertura então só atualiza os campos
        if (item.codigoInterno.toString().toUpperCase().endsWith(itemCobertura.codigoInterno.toString().toUpperCase())) {
          lista[index].checked = itemCobertura.checked;
          lista[index].descricaoBaseIdenizacao = itemCobertura.descricaoBaseIdenizacao;
          lista[index].valorPremioAdicional = itemCobertura.valorPremioAdicional;
          atualizou = true;
        } 
        return true;
      });

      // Se o item a ser atualizado ainda não existe na lista de coberturas Adicionais da apolice
      if (!atualizou) {
        var newItem = JSON.parse(JSON.stringify(InitialCoberturaAdicional.coberturaAdicional));
        newItem.codigoInterno = itemCobertura.codigoInterno;
        newItem.checked = itemCobertura.checked;
        newItem.descricaoBaseIdenizacao = itemCobertura.descricaoBaseIdenizacao;
        newItem.valorPremioAdicional = itemCobertura.valorPremioAdicional;

        lista.push(newItem);
      }

    
    } else {
      lista = lista.filter((item) => {
        return !item.codigoInterno.toString().toUpperCase().endsWith(itemCobertura.codigoInterno.toString().toUpperCase());
      });
    }

    lista = lista.filter((item) => {
      return !(item.codigoInterno.toString() === "");
    });

    lista = JSON.parse(JSON.stringify(lista));
    setCoberturasAdicionais(lista);
    updateTotalPremioAdicional(lista);
  }

  const updateTotalPremioAdicional = (listaAtualizada) => {
    var lista = JSON.parse(JSON.stringify(listaAtualizada));
    const premioBase = parseFloat(coberturaValues.cobertura.premioCobertura);
    var premioFinal = premioBase;

    lista.map(item => {
      const premioAdicional = parseFloat("0".concat(item.valorPremioAdicional));
      premioFinal = premioFinal + premioAdicional;
      return true;
    });

    
    //console.log({premioBase, premioFinal, lista});
    //var premio = JSON.parse(JSON.stringify(premioValues.premio));
    var premios = JSON.parse(JSON.stringify(premiosValues.premios))
    var caracteristicas = JSON.parse(JSON.stringify(caracteristicasValues.caracteristicas));
  
    caracteristicas.valorTotalPremio = premioFinal.toLocaleString('en-US', {maximumFractionDigits : 2}).replace(",", "");
    premios.valorTotalParcelas = caracteristicas.valorTotalPremio;
    setCaracteristicasValues({...caracteristicasValues, caracteristicas});
    onSetPremiosValues({...premiosValues , premios});


  }
  
  const HEADERCOLECTION = "header";
  const CARACTERISTICASCOLECION = "caracteristicas";
  const TOMADORCOLECTION = "tomador";
  const SEGURADOCOLECTION = "segurado";
  const COBERTURAADICIONALCOLECTION = "coberturadicional";
  //const PREMIOCOLECTION = "premio";
  const PREMIOSCOLECTION = "premios";
  const ENDOSSOCOLECTION = "endosso";

  const codigosCoberturaList = [
    {codigo: "701", valor : "Licitante"},
    {codigo: "702", valor : "Construção (Obras)"},
    {codigo: "703", valor : "Fornecimento"},
    {codigo: "704", valor : "Prestador de Serviços"},
    {codigo: "705", valor : "Garantia de Pagamentos"},
    {codigo: "706", valor : "Adiantamento de Pagamentos"},
    {codigo: "707", valor : "Manutenção Corretiva"},
    {codigo: "708", valor : "Judicial"},
    {codigo: "716", valor : "Judicial Civil"},
    {codigo: "717", valor : "Judicial Trabalhista"},
    {codigo: "718", valor : "Judicial Tributário"},
    {codigo: "719", valor : "Judicial Depósito Recursal"},
    {codigo: "709", valor : "Judicial para Execução Fiscal"},
    {codigo: "710", valor : "Parcelamento Administrativo "},
    {codigo: "711", valor : "Aduaneiro"},
    {codigo: "712", valor : "Administrativo de Créditos Tributários"},
    {codigo: "713", valor : "Locatícia"},
    {codigo: "714", valor : "Financeira"},
    {codigo: "715", valor : "Cobertura de Ações Trabalhistas e Previdenciárias"},
  ];

  const tiposEndossoList = [
    {codigo: "1", valor : "Sem movimentação de prêmio"},
    {codigo: "2", valor : "Com acréscimo de prêmio"},
    {codigo: "3", valor : "Com restituição de prêmio"},
    {codigo: "4", valor : "Cancelamento de Apólice com restituição de prêmio"},
    {codigo: "5", valor : "Cancelamento de Endosso com restituição de prêmio"},
    {codigo: "6", valor : "Cancelamento de Apólice sem restituição de prêmio"},
    {codigo: "7", valor : "Cancelamento de Endosso sem restituição de prêmio"},
    {codigo: "8", valor : "Reativação de apólice"},
    {codigo: "9", valor : "Reativação de endosso"},
    {codigo: "10", valor : "Averbação (Endosso de Faturamento)"},
    {codigo: "11", valor : "Averbação (Endosso de Cancelamento de Faturamento)"},
    {codigo: "12", valor : "Averbação (Endosso de Restituição de Faturamento)"},
    {codigo: "13", valor : "Averbação (Endosso sem Mov. Prêmio)"},
    {codigo: "14", valor : "Averbação (Endosso de Cancelamento com restituição)"},
  ];

  const setVencimentoParcela = (dataValue, index) => {
    var premios = JSON.parse(JSON.stringify(premiosValues.premios));
    premios.parcelas[index].premio.dataVencimento = dataValue.format("YYYYMMDD");

    setPremiosValues({...premiosValues, premios})
    //setFieldData(PREMIOSCOLECTION ,"dataVencimentoInicial",newValue)
  }

  const updateQuantidadeParcelas = (event) => {
    const qtdeParcelas = parseInt(event.target.value);
    updatePremios('qtdeParcelas', qtdeParcelas);
    var premios = JSON.parse(JSON.stringify(premiosValues.premios));

    var parcelas = [];
    var total = 0;
    for (let index = 0; index < qtdeParcelas; index++) {
      var parcela = JSON.parse(JSON.stringify(InitialPremio));
      const valor = premios.valorTotalParcelas / qtdeParcelas;

      const vencimento = dayjs(premios.dataVencimentoInicial).add(index*30, 'day');
      parcela.premio.valor = valor.toLocaleString('en-US', {maximumFractionDigits : 2}).replace(",", "");;
      if (index === (qtdeParcelas -1)) {
        const ultimaparcela = premios.valorTotalParcelas - total;
        parcela.premio.valor = ultimaparcela.toLocaleString('en-US', {maximumFractionDigits : 2}).replace(",", "");;
      }
      total = total + parseFloat(parcela.premio.valor);
      parcela.premio.parcela = index + 1;
      parcela.premio.dataVencimento = vencimento.format("YYYYMMDD");
      parcelas.push(parcela);

    }
    
    premios.parcelas = parcelas;
    premios.qtdeParcelas = qtdeParcelas;

    setPremiosValues({...premiosValues, premios});

  }

  const setValorParcela = (valor, index) => {
    var premios = JSON.parse(JSON.stringify(premiosValues.premios));
    premios.parcelas[index].premio.valor = valor;
    setPremiosValues({...premiosValues, premios});
  }
  

  const updateAcao = (value) => {
    updateHeader('acao', value);
    updateCaracteristicas('acao', value);
    updateTomador('acao', value);
    updateSegurado('acao', value);
    updateCoberturaAdicional('acao', value);
    updatePremios('acao', value);
    updateObjeto('acao', value);
    updateCobertura('acao', value);
    listCorretores.corretores.map((corretor, i) => updateCorretor(i,'acao', value));
    ;
  }

  const updateFuncao = (value) => {
    updateHeader('funcao', value);
    updateCaracteristicas('funcao', value);
    updateTomador('funcao', value);
    updateSegurado('funcao', value);
    updateCoberturaAdicional('funcao', value);
    updatePremios('funcao', value);
    updateObjeto('funcao', value);
    updateCobertura('funcao', value);
    updateEndosso('funcao', value);
    listCorretores.corretores.map((corretor, i) => updateCorretor(i,'funcao', value));
  }


  const updateHeader = (campo, valor) => {
    var header = JSON.parse(JSON.stringify(headerValues.header));
    header[campo] = valor.toString();
    return setHeaderValues({...headerValues , header});
  }

  const updateCaracteristicas = (campo, valor) => {
    if (!loading) {
      var data = JSON.parse(JSON.stringify(caracteristicasValues.caracteristicas));
      data[campo] = valor.toString();
      return setCaracteristicasValues({...caracteristicasValues , caracteristicas: data});
    }
  }

  const updateTomador = (campo, valor) => {
    var data = JSON.parse(JSON.stringify(tomadorValues.pessoa));
    data[campo] = valor.toString();
    return setTomadorValues({...tomadorValues , pessoa: data});
  }

  const updateSegurado = (campo, valor) => {
    var data = JSON.parse(JSON.stringify(seguradoValues.pessoa));
    data[campo] = valor.toString();
    return setSeguradoValues({...seguradoValues , pessoa: data});
  }

  const updateCoberturaAdicional = (campo, valor) => {
    var data = JSON.parse(JSON.stringify(coberturaAdicionalValues.coberturaAdicional));
    data[campo] = valor.toString();
    return setCoberturaAdicionalValues({...coberturaAdicionalValues , coberturaAdicional: data});
  }

/*  const updatePremio = (campo, valor) => {
    var data = JSON.parse(JSON.stringify(premioValues.premio));
    data[campo] = valor.toString();
    return setPremioValues({...premioValues , premio: data});
  }
    */

  const updatePremios = (campo, valor) => {
    var data = JSON.parse(JSON.stringify(premiosValues.premios));
    data[campo] = valor.toString();
    return onSetPremiosValues({...premiosValues, premios: data});
  }

  const updateObjeto = (campo, valor) => {
    var data = JSON.parse(JSON.stringify(objetoValues.objeto));
    data[campo] = valor.toString();
    return setObjetoValues({...objetoValues , objeto: data});
  }

  const updateCobertura = (campo, valor) => {
    var data = JSON.parse(JSON.stringify(coberturaValues.cobertura));
    data[campo] = valor.toString();
    return setCoberturaValues({...coberturaValues , cobertura: data});
  }

  const updateEndosso = (campo, valor) => {
    var endosso = JSON.parse(JSON.stringify(endossoValues.endosso));
    endosso[campo] = valor.toString();
    return setEndossoValues({...endossoValues , endosso});
  }


  const updateCorretor = (index, campo, valor) => {
    var oldCorretores = JSON.parse(JSON.stringify(listCorretores.corretores));
    var data = oldCorretores[index];
    data[campo] = valor.toString();
    oldCorretores[index] = data;
    return setListCorretores({...listCorretores , corretores: oldCorretores});
  }

  const addCorretor = () => {
    const corretor = JSON.parse(JSON.stringify(listCorretores.corretor));
    var oldCorretores = listCorretores.corretores;
    oldCorretores.push(corretor);
    return setListCorretores({...listCorretores , corretores: oldCorretores});
  }

  const removeCorretor = (index) => {
    var oldCorretores = listCorretores.corretores;
    delete oldCorretores[index];
    return setListCorretores({...listCorretores , corretores: oldCorretores});

  }
  
  const criarArquivo = () => {

    if (headerValues.header.tipoDoc === "ENDOSSO") {
      updateFuncao('REGEN'); 

    }

    const header = headerValues.criarRegHeaderTxt(headerValues.header);
    const caracteristicas = caracteristicasValues.criarRegCaracteristicasGeraisTxt(caracteristicasValues.caracteristicas);
    const tomador = tomadorValues.criarRegPessoaTxt(tomadorValues.pessoa);
    const segurado = seguradoValues.criarRegPessoaTxt(seguradoValues.pessoa);
    const cobertura = coberturaValues.criarRegCoberturaTxt(coberturaValues.cobertura);
    //const coberturaAdicional = coberturaAdicionalValues.criarRegCoberturaAdicionalTxt(coberturaAdicionalValues.coberturaAdicional);
    //const premio = premioValues.criarRegPremioTxt(premioValues.premio);
    const premios = premiosValues.criarRegPremiosTxt(premiosValues.premios);
    const objeto = objetoValues.criarRegObjetoTxt(objetoValues.objeto);
    const endosso = endossoValues.criarRegEndossoTxt(endossoValues.endosso);
    var corretores = "";
    var informarCorretores = false;
    listCorretores.corretores.map(corretor => {
      informarCorretores = (corretor.informarCorretor === 'S');
      const corretortxt = listCorretores.criarRegCorretorTxt(corretor);
      corretores = corretores + corretortxt + String.fromCharCode(13);
      return corretores;
    })
    const alteracao = headerValues.criarRegMotivoAlteracao(headerValues.header);

    const apolice = createApoliceObj();


    var resp = header + String.fromCharCode(13);
    resp = resp + caracteristicas + String.fromCharCode(13);
    if (informarCorretores) resp = resp + corretores;
    resp = resp + tomador + String.fromCharCode(13);
    if (headerValues.header.tipoDoc === "ENDOSSO")
      resp = resp + endosso + String.fromCharCode(13);
    resp = resp + segurado + String.fromCharCode(13);
    resp = resp + objeto + String.fromCharCode(13);
    resp = resp + cobertura + String.fromCharCode(13);
    //resp = resp + coberturaAdicional + String.fromCharCode(13);
    resp = resp + premios + String.fromCharCode(13);
    if (headerValues.header.acao === "ALTR")
       resp = resp + alteracao + String.fromCharCode(13);
    setArquivo(resp);

    BackAppFunctions.SaveApoliceTXT(apolice, resp);

  }

  const loadApolice = (apoliceObj) => {
    console.log({apoliceObj});
    setHeaderValues({...headerValues, header: apoliceObj.header});
                              
    setCaracteristicasValues({...caracteristicasValues, caracteristicas: apoliceObj.caracteristicas});
    if (apoliceObj.corretor) {
      setListCorretores({...listCorretores, corretores: [apoliceObj.corretor]});
    } else {
      setListCorretores({...listCorretores, corretores: apoliceObj.corretores});
    }
    setTomadorValues({...tomadorValues, pessoa: apoliceObj.tomador});
    setSeguradoValues({...seguradoValues, pessoa: apoliceObj.segurado});
    setCoberturaValues({...coberturaValues, cobertura: apoliceObj.cobertura});
    if (apoliceObj.coberturasAdicionais) {
      initiateOpcoesCoberturasAdicionais(apoliceObj.cobertura.codigoCobertura);
      setCoberturasAdicionais(apoliceObj.coberturasAdicionais);
    }else {
      initiateOpcoesCoberturasAdicionais(apoliceObj.cobertura.codigoCobertura);
      setCoberturasAdicionais([]);
    }
    
    setCoberturaAdicionalValues({...coberturaAdicionalValues, coberturaAdicional: apoliceObj.coberturaAdicional});
    //setPremioValues({...premioValues, premio: apoliceObj.premio});
    var premios = apoliceObj.premios;
    if (!premios){
      premios = JSON.parse(JSON.stringify(InitialPremios.premios));
      premios.valorTotalParcelas = apoliceObj.premio.valor;
      premios.dataVencimentoInicial = apoliceObj.premio.dataVencimento;
      premios.qtdeParcelas = 1;
      premios.parcelas = [{...InitialPremio, premio: apoliceObj.premio}];

    }
    setPremiosValues({...premiosValues, premios});
    setObjetoValues({...objetoValues, objeto: apoliceObj.objeto});
    if (apoliceObj.endosso) {
      setEndossoValues({...endossoValues, endosso: apoliceObj.endosso});
    }
  }

  const createApoliceObj = () => {

    const header = headerValues.header;
    const caracteristicas = caracteristicasValues.caracteristicas;
    const corretores = listCorretores.corretores;
    const tomador = tomadorValues.pessoa;
    const segurado = seguradoValues.pessoa;
    const cobertura = coberturaValues.cobertura;
    const coberturaAdicional = coberturaAdicionalValues.coberturaAdicional;
    //const coberturasAdicionais = cobertura.coberturasAdicionais;
    //const premio = premioValues.premio;
    const premios = premiosValues.premios;
    const objeto = objetoValues.objeto;
    const endosso = endossoValues.endosso;


    var apolice = {header, caracteristicas, corretores, tomador, segurado, cobertura, coberturasAdicionais, coberturaAdicional, premios, objeto};

    if (header.tipoDoc === "ENDOSSO") {
      apolice["endosso"] = endosso;
      updateFuncao('REGEN');
    }

    console.log({apolice});



    return apolice;

  }
  
  const clonarApolice = async () => {
    //updateCaracteristicas("numeroApolice", "");
    updateHeader("idInterno", "");
    setClone(true);
  }

  const salvarApolice = async () => {
    setLoading(true);

    var caracteristicas = caracteristicasValues.caracteristicas;
    var apolice = createApoliceObj();

    const res = await BackAppFunctions.SaveApolice(apolice);
    setClone(false);
    caracteristicas = res.apolice.caracteristicas;
    const header = res.apolice.header;

    updateCaracteristicas("numeroApolice", caracteristicas.numeroApolice);
    updateHeader('idInterno', header.idInterno);


    //navigate('/dashboard/apoliceedit/'.concat(caracteristicas.numeroApolice), { replace: true });
    navigate('/dashboard/apoliceedit/'.concat(header.idInterno), { replace: true });
    setLoading(false);
  }

  const criarEndosso = async () => {
    setLoading(false);
    const header = headerValues.header;
    const caracteristicas = caracteristicasValues.caracteristicas;
    const corretores = listCorretores.corretores;
    const tomador = tomadorValues.pessoa;
    const segurado = seguradoValues.pessoa;
    const cobertura = coberturaValues.cobertura;
    const coberturaAdicional = coberturaAdicionalValues.coberturaAdicional;
    //const premio = premioValues.premio;
    const premios = premiosValues.premios;
    const objeto = objetoValues.objeto;
    const endosso = endossoValues.endosso;

    const apolice = {header, endosso, caracteristicas, corretores, tomador, segurado, cobertura, coberturaAdicional, premios, objeto};

    const res = await BackAppFunctions.CreateApolice(apolice);
    console.log(res);
    window.open(res.url, '_blank');
    setUrlApolice(res.url);
    setLoading(false);

  }

  const criarApolice = async () => {
    setLoading(true);
    const header = headerValues.header;
    const caracteristicas = caracteristicasValues.caracteristicas;
    const corretores = listCorretores.corretores;
    const tomador = tomadorValues.pessoa;
    const segurado = seguradoValues.pessoa;
    const cobertura = coberturaValues.cobertura;
    const coberturaAdicional = coberturaAdicionalValues.coberturaAdicional;
    //const premio = premioValues.premio;
    const premios = premiosValues.premios;
    const objeto = objetoValues.objeto;
    const adicionais = coberturasAdicionais.filter(item => {
      return (item !== null) && (item.codigoInterno !== null)
    })


    const apolice = {header, caracteristicas,  corretores, tomador, segurado, cobertura, coberturasAdicionais: adicionais,  coberturaAdicional, premios, objeto};

    const res = await BackAppFunctions.CreateApolice(apolice);
    console.log(res);
    setUrlApolice(res.url);
    console.log({urlApolice});
    window.open(res.url, '_blank');
    
    setLoading(false);

  }

  const setIdNumDoc = (apolice) => {
    if (apolice.caracteristicas.numeroApolice === "") {
      updateCaracteristicas('numeroApolice', apolice.header.idInterno);
    }
    
  }

  const criarMinuta = async () => {
    setLoading(true);
    
    const header = headerValues.header;
    const caracteristicas = caracteristicasValues.caracteristicas;
    const corretores = listCorretores.corretores;
    const tomador = tomadorValues.pessoa;
    const segurado = seguradoValues.pessoa;
    const cobertura = coberturaValues.cobertura;
    const coberturaAdicional = coberturaAdicionalValues.coberturaAdicional;
    //                    const premio = premioValues.premio;
    const premios = premiosValues.premios;
    const objeto = objetoValues.objeto;
    const adicionais = coberturasAdicionais.filter(item => {
      return (item !== null) && (item.codigoInterno !== null)
    })

    const apolice = {header, caracteristicas, corretores, tomador, segurado, cobertura, coberturasAdicionais: adicionais, coberturaAdicional, premios, objeto};

    const res = await BackAppFunctions.CreateMinuta(apolice);
    console.log(res);
    window.open(res.url, '_blank');
    setUrlApolice(res.url);
    setLoading(false);

  }

  
  useEffect(() => {
    if (clone) {
        salvarApolice();
    }

  // eslint-disable-next-line
}, [clone]);

  /*
  useEffect(() => {
    if (coberturaValues.cobertura){
      if (coberturaValues.cobertura.codigoCobertura) {
        if (opcoesCoberturasAdicionais.length === 0) {
          updateCodigoCobertura(coberturaValues.cobertura.codigoCobertura);
        }
      }
    }
  // eslint-disable-next-line     
  }, [coberturaValues])
  */

  useEffect(() => {
    //Iniciar com valores default
    if (id) {
      BackAppFunctions.LoadApolice(id).then(resp => {
        
        if (resp.apolice){
          setLoading(true);
          loadApolice(resp.apolice);
          setTitulo("Editar Apólice");
          setIdNumDoc(resp.apolice);
          setLoading(false);
        } else {
          navigate('/dashboard/apolicenew', { replace: true });
        }
      });
    } else {
      setLoading(true);
      //setFieldData(HEADERCOLECTION, 'data', dataAtual);
      updateDataObj(dataAtual);
      var data = JSON.parse(JSON.stringify(caracteristicasValues.caracteristicas));
  
      //data["dataEmissao"] = dataAtual.format("YYYYMMDD");
      data["dataInicioVigencia"] = dataAtual.format("YYYYMMDD");
      data["dataTerminoVigencia"] = dataAtual.format("YYYYMMDD");
      data["dataTerminoVigencia"] = dataAtual.format("YYYYMMDD");
      
      //setCaracteristicasValues({...caracteristicasValues , caracteristicas: data});
      //updateTomador("funcaoPessoa", "1"); // Funcao Tomador
      //updateSegurado("funcaoPessoa", "2"); // Funcao Segurado
      setLoading(false);
    }

  // eslint-disable-next-line
  }, []);

  const updateDataObj = (event) => {
    setFieldData(HEADERCOLECTION, 'data', event);
    const dataVencimento = dayjs(event).add(7, 'day');
    updatePremios("dataVencimentoInicial", dataVencimento.format("YYYYMMDD"));

  }

  

  const setFieldData = (colecao, nomeCampo, dataValue) => {
    switch (colecao) {
      case HEADERCOLECTION:
        return updateHeader(nomeCampo, dataValue.format("YYYYMMDD"));
      case CARACTERISTICASCOLECION:
        return updateCaracteristicas(nomeCampo, dataValue.format("YYYYMMDD"));
      case TOMADORCOLECTION:
        return updateTomador(nomeCampo, dataValue.format("YYYYMMDD"));
      case SEGURADOCOLECTION:
        return updateSegurado(nomeCampo, dataValue.format("YYYYMMDD"));
      case COBERTURAADICIONALCOLECTION:
        return updateCoberturaAdicional(nomeCampo, dataValue.format("YYYYMMDD"));
      //case PREMIOCOLECTION:
      //  return updatePremio(nomeCampo, dataValue.format("YYYYMMDD"));
      case PREMIOSCOLECTION:
        return updatePremios(nomeCampo, dataValue.format("YYYYMMDD"));
      case ENDOSSOCOLECTION:
        return updateEndosso(nomeCampo, dataValue.format("YYYYMMDD"));
      default:
        return updateHeader(nomeCampo, dataValue.format("YYYYMMDD"));
    
    }
  }

  const setLimiteMaximoCobertura = (valor) => {
    const valorNew = converterStringParaNumero(valor);
    updateCaracteristicas('limiteMaximoGarantia',valorNew);
    updateCobertura("limiteMaximoIdenizacao", valorNew);
  }

  const setValorPremio = (valor) =>{
    const valorNew = converterStringParaNumero(valor);

    var data = JSON.parse(JSON.stringify(caracteristicasValues.caracteristicas));
    data["valorTotalPremio"] = valorNew;
    data["valorTotalPremioComercial"] = valorNew;
    setCaracteristicasValues({...caracteristicasValues , caracteristicas: data});

    updateCobertura("premioCobertura", valorNew);
    updatePremios("valorTotalParcelas", valorNew);
  }

  const initiateOpcoesCoberturasAdicionais = (codigoRamo) => {
    var listaAdicional = [];
    switch (codigoRamo) {
      case "704": // Prestação de Serviços
        listaAdicional = adicionaisPrestacaoServicos;
        break;
      case "713": // Prestação de Serviços
        listaAdicional = adicionaisLocaticios;
        break;
      case "705": // adicionais ga
        listaAdicional = adicionaisGarantiasPagamentos;
        break;
    
    default:
      listaAdicional = adicionaisPadrao;
      break;
    }

    
    //updateValuesCoberturasAdicionais(listaAdicional);
    setOpcoesCoberturasAdicionais(listaAdicional);
    return listaAdicional;

  }

  useEffect(() => {
    updateValuesOpcoesCoberturasAdicionais(coberturasAdicionais);
    // eslint-disable-next-line
  }, [coberturasAdicionais]);

  const updateValuesOpcoesCoberturasAdicionais = (lista) => {
    var opcoesCoberturasAdicionaisTemp = JSON.parse(JSON.stringify(opcoesCoberturasAdicionais));
    var adicionais = JSON.parse(JSON.stringify(lista));

    adicionais.map((cobertura, i) => {
      if (cobertura){
        opcoesCoberturasAdicionaisTemp.map((item, y) => {
          console.log({item, cobertura});
          if (item.codigoInterno.toString().endsWith(cobertura.codigoInterno?cobertura.codigoInterno.toString():"xxx")) {
            opcoesCoberturasAdicionaisTemp[y].checked = true;
            opcoesCoberturasAdicionaisTemp[y].descricaoBaseIdenizacao = cobertura.descricaoBaseIdenizacao;
            opcoesCoberturasAdicionaisTemp[y].valorPremioAdicional = cobertura.valorPremioAdicional;
          }
          return true;
        });
      }
      setOpcoesCoberturasAdicionais(opcoesCoberturasAdicionaisTemp);
      return true;
    });

    
  }

  const updateCodigoCobertura = (codigoRamo) => {
    //console.log("updateCodigoCobertura");
    const item = codigosCoberturaList.find(item => item.codigo === codigoRamo);
    
    //updateListaOpcoesCoberturasAdicionais(codigoRamo);
    const lista = initiateOpcoesCoberturasAdicionais(codigoRamo);
    setOpcoesCoberturasAdicionais(lista);
    updateTotalPremioAdicional([]);

    var data = JSON.parse(JSON.stringify(coberturaValues.cobertura));
    data["codigoCobertura"] = codigoRamo;
    data["descricaoCobertura"]= item.valor;//.toUpperCase();
    setCoberturaValues({...coberturaValues , cobertura: data});

  }
  


  

  return (
    <Container maxWidth="xl" style={{backgroundColor: "#F0F0F0", height: "100%"}} >
        {(loading) ? <Box display="flex" justifyContent="center"  alignItems="center" style={{height: "100%"}}><CircularProgress /></Box> : (
        <Grid container spacing={3} >
          <Grid item xs={12}>
              <Typography variant="h4" sx={{ mb: 5 }}>
                    {titulo}
              </Typography>
          </Grid>
          <Grid item xs={3}>
            <div>Número da Proposta</div>
              <TextField name='codigoOjeto' fullWidth 
              value={objetoValues.objeto.codigoOjeto} 
              onChange={(event) => updateObjeto("codigoOjeto", event.target.value)}
              />
          </Grid>
          <Grid item xs={6}>
            <div>Número de Registro da Apólice</div>
              <TextField name='codigosusep' 
                    value={caracteristicasValues.caracteristicas.codigoSusep} 
                    fullWidth 
                    onChange={(event) => updateCaracteristicas("codigoSusep", event.target.value)} />
          </Grid>
          <Grid item xs={3}>
            <div>Número Documento B3</div>
            <TextField name='numeroapolice' 
                  value={caracteristicasValues.caracteristicas.numeroApolice} fullWidth onChange={(event) => updateCaracteristicas("numeroApolice", event.target.value)} />
          </Grid>


          <Grid item xs={2}>
            <div>Tipo</div>
            <Select id="acao" value={headerValues.header.tipoDoc === ""? "MINUTA": (headerValues.header.tipoDoc?headerValues.header.tipoDoc:"MINUTA")} fullWidth 
            onChange={(event) => updateHeader('tipoDoc', event.target.value)} >
              <MenuItem value={"MINUTA"} >MINUTA</MenuItem>
              <MenuItem value={"APÓLICE"}  >APÓLICE</MenuItem>
              <MenuItem value={"ENDOSSO"}  >ENDOSSO</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={10}>
              <div>Título</div>
              <TextField name='tituloDoc' fullWidth 
                    value={headerValues.header.tituloDoc} 
                    onChange={(event) => {updateHeader('tituloDoc', event.target.value)}} />
          </Grid>

          {(headerValues.header.tipoDoc)? (headerValues.header.tipoDoc === "ENDOSSO") ? 
          <Grid item xs={12}> 
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}
                  aria-controls='panel1-content'
                  id="panel1-header">
                    <Typography>
                      <b>Dados do Endosso</b>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={1}>
                      <div>Número</div>
                      <TextField name='numEndosso' fullWidth 
                            value={endossoValues.endosso.numEndosso} 
                            onChange={(event) => {updateEndosso('numEndosso', event.target.value)}} />
                    </Grid>
                    <Grid item xs={3}>
                      <div>Endosso Associado</div>
                      <TextField name='numEndossoAssociado' fullWidth 
                            value={endossoValues.endosso.numEndossoAssociado} 
                            onChange={(event) => {updateEndosso('numEndossoAssociado', event.target.value)}} />
                    </Grid>

                    <Grid item xs={4}>
                        <div>Tipo do Endosso </div>
                        <Select ref={codigoTipoIndossoRef} id="codigoEndosso" 
                          value={endossoValues.endosso.tipoEndosso} fullWidth 
                          onChange={(event) => {updateEndosso("tipoEndosso", event.target.value)}}>
                          {tiposEndossoList.map((item) => 
                            (<MenuItem key={item.codigo} value={item.codigo} >{item.valor}</MenuItem>)
                          )}
                        </Select>
                    </Grid>
                    <Grid item xs={4}>
                      <div>Motivo do Endosso</div>
                      <TextField name='motivoEndoso' fullWidth 
                            value={endossoValues.endosso.motivo} 
                            onChange={(event) => {updateEndosso('motivo', event.target.value)}} />
                    </Grid>

                    <Grid item xs={4}>
                      <div>Emissão Endosso:  </div>
                      <DateTimePicker views={['year', 'month', 'day' ]} 
                            value={dayjs(endossoValues.endosso.dataEmissaoEndosso)}  
                            onChange={(event) => setFieldData(ENDOSSOCOLECTION, 'dataEmissaoEndosso', event)}  slotProps={{ textField: { fullWidth: true } }} />
                    </Grid>
                    <Grid item xs={4}>
                      <div>Início Vigência Endosso:  </div>
                      <DateTimePicker views={['year', 'month', 'day' ]} 
                            value={dayjs(endossoValues.endosso.dataInicioVigenciaEndosso)}  
                            onChange={(event) => setFieldData(ENDOSSOCOLECTION, 'dataInicioVigenciaEndosso', event)}  slotProps={{ textField: { fullWidth: true } }} />
                    </Grid>
                    <Grid item xs={4}>
                      <div>Termino Vigência Endosso:  </div>
                      <DateTimePicker views={['year', 'month', 'day' ]} 
                            value={dayjs(endossoValues.endosso.dataTerminoVigênciaEndosso)}  
                            onChange={(event) => setFieldData(ENDOSSOCOLECTION, 'dataTerminoVigênciaEndosso', event)}  slotProps={{ textField: { fullWidth: true } }} />
                    </Grid>


                  </Grid>
                  </AccordionDetails> 
            </Accordion>
          </Grid> 
          : <></> :<></>}

          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls='panel1-content'
                id="panel1-header">
                  <Typography>
                    <b>Dados da Infinite Seguradora</b>
                  </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <div>Ação</div>
                    <Select id="acao" value={headerValues.header.acao} fullWidth 
                    onChange={(event) => {updateAcao(event.target.value)}} >
                      <MenuItem value={"INCL"} >Incluir</MenuItem>
                      <MenuItem value={"ALTR"}  >Alterar</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={4}>
                    <div>Data: </div>
                    <DateTimePicker views={['year', 'month', 'day' ]} 
                          value={dayjs(headerValues.header.data)}  
                          onChange={(event) => updateDataObj(event)}  slotProps={{ textField: { fullWidth: true } }} />
                  </Grid>
                  <Grid item xs={4}>
                    <div>Layout</div>
                    <Select id="versaoLayout" value={headerValues.header.versaoLayout} fullWidth 
                    onChange={(event) => {updateHeader('versaoLayout', event.target.value)}} >
                      <MenuItem value={"1"}  selected={headerValues.header.versaoLayout.endsWith("1")}  >Versão Inicial</MenuItem>
                      <MenuItem value={"4"}  selected={headerValues.header.versaoLayout.endsWith("4")} >Versão com Campos</MenuItem>
                      <MenuItem value={"5"}  selected={headerValues.header.versaoLayout.endsWith("5")} >Versão Atual (5)</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <div>Sucursal</div>
                    <TextField name='sucursal' disabled fullWidth 
                          value={caracteristicasValues.caracteristicas.descSucursal} />
                  </Grid>

                  <Grid item xs={4}>
                    <div>UF Sucursal</div>
                    <TextField name='ufsucursal' disabled fullWidth 
                          value={caracteristicasValues.caracteristicas.ufSucursal} />
                  </Grid>

                  <Grid item xs={4}>
                    <div>Nº da Conta B3</div>
                    <TextField name='contaseguradora' disabled fullWidth 
                          value={headerValues.header.contaSeguradora} />
                  </Grid>
                  {headerValues.header.acao === "ALTR" ? (
                  <Grid item xs={12}>
                    <div>Motivo da Alteração</div>
                    <TextField name='motivoAlteracao' fullWidth 
                          value={headerValues.header.motivoAlteracao} 
                          onChange={(event) => {updateHeader('motivoAlteracao', event.target.value)}} />
                  </Grid>
                  ): <></>}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls='panel1-content'
                id="panel1-header">
                  <Typography>
                  <b>Características Gerais</b>
                  </Typography>
                  
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <div>Data Emissão</div>
                      <DateTimePicker id="dataemissao" views={['year', 'month', 'day' ]} 
                            value={dayjs(caracteristicasValues.caracteristicas.dataEmissao)} 
                            onChange={(newValue) => setFieldData(CARACTERISTICASCOLECION ,"dataEmissao",newValue)}  slotProps={{ textField: { fullWidth: true } }} />
                    </Grid>
                    
                    <Grid item xs={4}>
                      <div>Início Vigência</div>
                      <DateTimePicker id="datainiciovigencia" views={['year', 'month', 'day' ]} 
                            value={dayjs(caracteristicasValues.caracteristicas.dataInicioVigencia)} onChange={(newValue) => setFieldData(CARACTERISTICASCOLECION ,"dataInicioVigencia",newValue)}  slotProps={{ textField: { fullWidth: true } }} />
                    </Grid>

                    <Grid item xs={4}>
                      <div>Termino Vigência</div>
                      <DateTimePicker id="dataterminovigencia" views={['year', 'month', 'day' ]} 
                            value={dayjs(caracteristicasValues.caracteristicas.dataTerminoVigencia)} onChange={(newValue) => setFieldData(CARACTERISTICASCOLECION ,"dataTerminoVigencia",newValue)}  slotProps={{ textField: { fullWidth: true } }} />
                    </Grid>

                    <Grid item xs={4}>
                      <div>Tipo de Documento</div>
                        
                      <Select id="tipodocumento"  value={caracteristicasValues.caracteristicas.tipoDocumento} fullWidth onChange={(event) => {updateCaracteristicas('tipoDocumento', event.target.value)}}>
                        <MenuItem value={"1"} selected>Emissão Própria</MenuItem>
                        <MenuItem value={"2"} >Coseguro Aceito</MenuItem>
                      </Select>
                    </Grid>


                    <Grid item xs={4}>
                      <div>Tipo de Contrato</div>
                      <Select id="tipocontratacao" value={caracteristicasValues.caracteristicas.tipoContratacao} fullWidth onChange={(event) => {updateCaracteristicas('tipoContratacao', event.target.value)}}>
                        <MenuItem value={"1"} selected>Individual</MenuItem>
                        <MenuItem value={"2"} >Coletivo</MenuItem>
                        <MenuItem value={"3"} >Bilhete</MenuItem>
                        <MenuItem value={"4"} >Certificado</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                    <div>Total IOF</div>
                      <TextField name='IOF' fullWidth disabled
                            value={caracteristicasValues.caracteristicas.iofTotal} onChange={(event) => updateCaracteristicas("iofTotal", event.target.value)} />
                    </Grid>
                    <Grid item xs={4}>
                    <div>Moeda</div>
                      <TextField name='moedaEmissao' disabled fullWidth 
                            value={caracteristicasValues.caracteristicas.moedaEmissao} onChange={(event) => updateCaracteristicas("moedaEmissao", event.target.value)} />
                    </Grid>
                    <Grid item xs={4}>
                    <div>Valor Total do Prêmio </div>
                      <TextField name='valorTotalPremio' fullWidth 
                                 value={caracteristicasValues.caracteristicas.valorTotalPremio}  
                                 onChange={(event) => setValorPremio(event.target.value)} />
                    </Grid>
                    {/**
                    <Grid item xs={4}>
                    <div>Valor Total Prêmio Comercial</div>
                      <TextField name='valorTotalPremioComercial' fullWidth
                                 value={caracteristicasValues.caracteristicas.valorTotalPremioComercial}  
                                 onChange={(event) => setValorPremio(event.target.value)} />
                    </Grid>
                     */}
                    <Grid item xs={4}>
                    <div>Limite Máximo Garantia</div>
                      <TextField name='limiteMaximoGarantia' fullWidth 
                            value={caracteristicasValues.caracteristicas.limiteMaximoGarantia} onChange={(event) => setLimiteMaximoCobertura(event.target.value)} />
                    </Grid>
                    
                    
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls='panel1-content' id="panel1-header">
                <Grid container spacing={2}>
                    <Grid item xs={10} >
                      <Typography><b>Corretores / Intermediadores</b></Typography>
                    </Grid>
                    <Grid item xs={2}><Button  size="small" variant='contained' onClick={() => addCorretor()}>+ Adicionar</Button> </Grid>

                  </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <>
                <div style={{height: 20, width: "100%", }}></div>
                  {listCorretores.corretores.map((corretor, i) => (
                    <Card variant="outlined" key={i} >
                      <CardContent>

                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <div>Informar </div>
                            <Select id="informarCorretor" fullWidth value={corretor.informarCorretor}  
                            onChange={(event) => {updateCorretor(i, 'informarCorretor', event.target.value)}}>
                              <MenuItem value={"S"} >SIM</MenuItem>
                              <MenuItem value={"N"} >NÃO</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={4}>
                            <div>Tipo de Intermediador</div>
                            <Select id="tipoItermediador" fullWidth 
                              value={corretor.tipoItermediador} onChange={(event) => {updateCorretor(i, 'tipoItermediador', event.target.value)}}>
                              <MenuItem value={"1"} selected >Corretor </MenuItem>
                              <MenuItem value={"2"} >Representante de Seguros </MenuItem>
                              <MenuItem value={"3"} >Estipulante </MenuItem>
                              <MenuItem value={"4"} >Correspondente de Microsseguros </MenuItem>
                              <MenuItem value={"5"} >Agente de Seguros </MenuItem>
                              <MenuItem value={"6"} >Averbador </MenuItem>
                              <MenuItem value={"7"} >Instituidor  </MenuItem>
                              <MenuItem value={"99"} >Outros</MenuItem>
                            </Select>
                          </Grid>
                          

                          <Grid item xs={4}>
                            <div>CPF / CNPJ</div>
                              <TextField name='cpfCnpj' fullWidth 
                                    value={corretor.cpfCnpj} onChange={(event) => updateCorretor(i,"cpfCnpj", removerCaracteresNaoNumericos(event.target.value))} />
                          </Grid>

                          <Grid item xs={4}>
                            <div>Nome / Razão Social</div>
                              <TextField name='nomeRazaoSocial' fullWidth 
                                    value={corretor.nomeRazaoSocial} 
                                    onChange={(event) => updateCorretor(i,"nomeRazaoSocial", event.target.value)} />
                          </Grid>

                          <Grid item xs={4}>
                            <div>Código SUSEP</div>
                              <TextField name='codigoIntermediador' fullWidth 
                                    value={corretor.codigoIntermediador} onChange={(event) => updateCorretor(i,"codigoIntermediador", event.target.value)} />
                          </Grid>


                          <Grid item xs={4}>
                            <div>Valor da Comissão</div>
                              <TextField name='valorComissao' fullWidth 
                              value={corretor.valorComissao} 
                              onChange={(event) => updateCorretor(i,"valorComissao", converterStringParaNumero(event.target.value))} />
                          </Grid>


                          <Grid item xs={4}>
                            <div>Nacionalidade</div>
                            <Select id="nacionalidade" value={corretor.nacionalidade} fullWidth onChange={(event) => {updateCorretor(i,'nacionalidade', event.target.value)}}>
                              <MenuItem value={"S"} >Brasileira</MenuItem>
                              <MenuItem value={"N"} >Extrageira</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={4}>
                            <div>Tipo de Pessoa</div>
                            <Select id="tipoPessoa" value={corretor.tipoPessoa} fullWidth onChange={(event) => {updateCorretor(i,'tipoPessoa', event.target.value)}}>
                              <MenuItem value={"PF"} >Pessoa Física </MenuItem>
                              <MenuItem value={"PJ"} >Pessoa Jurídica </MenuItem>
                            </Select>
                          </Grid>


                          <Grid item xs={4}>
                            <div>Email</div>
                              <TextField name='email' fullWidth 
                              value={corretor.email} onChange={(event) => updateCorretor(i,"email", event.target.value)} />
                          </Grid>
                          <Grid item xs={4}>
                            <div>Endereço</div>
                              <TextField name='logradouro' fullWidth 
                                    value={corretor.logradouro} onChange={(event) => updateCorretor(i,"logradouro", event.target.value)} />
                          </Grid>
                          <Grid item xs={4}>
                            <div>Número</div>
                              <TextField name='numero' fullWidth 
                              value={corretor.numero} onChange={(event) => updateCorretor(i,"numero", event.target.value)} />
                          </Grid>
                          <Grid item xs={4}>
                            <div>Complemento</div>
                              <TextField name='complemento' fullWidth 
                                    value={corretor.complemento} onChange={(event) => updateCorretor(i,"complemento", event.target.value)} />
                          </Grid>
                          <Grid item xs={4}>
                            <div>Bairro</div>
                              <TextField name='bairro' fullWidth 
                              value={corretor.bairro} onChange={(event) => updateCorretor(i,"bairro", event.target.value)} />
                          </Grid>
                          <Grid item xs={4}>
                            <div>Município</div>
                              <TextField name='municipio' fullWidth 
                              value={corretor.municipio} onChange={(event) => updateCorretor(i,"municipio", event.target.value)} />
                          </Grid>
                          <Grid item xs={4}>
                            <div>UF</div>
                              <TextField name='uf' fullWidth 
                              value={corretor.uf} onChange={(event) => updateCorretor(i,"uf", event.target.value)} />
                          </Grid>
                          <Grid item xs={4}>
                            <div>CEP</div>
                              <TextField name='codigoPostal' fullWidth 
                              value={corretor.codigoPostal} 
                              onChange={(event) => updateCorretor(i,"codigoPostal", removerCaracteresNaoNumericos(event.target.value))} />
                          </Grid>
                          <Grid item xs={4}>
                            <div>Num Documento Extrangeiro</div>
                              <TextField name='numDocExtrangeiro' fullWidth 
                              value={corretor.numDocExtrangeiro} onChange={(event) => updateCorretor(i,"numDocExtrangeiro", event.target.value)} />
                          </Grid>
                          <Grid item xs={4}>
                            <div>Pais Residência</div>
                            <Select id="paisResidencia" fullWidth 
                                value={corretor.paisResidencia} onChange={(event) => {updateCorretor(i,'paisResidencia', event.target.value)}}>
                                <MenuItem value={"1"} selected>Brasil</MenuItem>
                              </Select>
                          </Grid>

                          <Grid item xs={4}>
                            <div>Observações</div>
                              <TextField name='observacoes' fullWidth 
                              value={corretor.observacoes} 
                              onChange={(event) => updateCorretor(i,"observacoes", event.target.value)} />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={3} justifyContent="flex-end" direction="row">
                              <Grid item >
                                <Button  size="small" variant='contained'  color="error" onClick={() => removeCorretor(i)}>Remover</Button> 
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} style={{height: 10}} ></Grid>
                        </Grid>
                      </CardContent >
                      <div style={{height: 20, width: "100%", }}></div>
                    </Card>
                  ))}
              </>
              
              

              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls='panel1-content'
                id="panel1-header">
                  <Typography>
                  <b>Tomador</b>
                  </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <div>Nacionalidade</div>
                    <Select id="nacionalidade" value={tomadorValues.pessoa.nacionalidade} fullWidth onChange={(event) => {updateTomador('nacionalidade', event.target.value)}}>
                      <MenuItem value={"S"} selected>Brasileiro</MenuItem>
                      <MenuItem value={"N"} >Extrangeiro</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <div>Tipo de Pessoa</div>
                    <Select id="tipoPessoa" value={tomadorValues.pessoa.tipoPessoa} fullWidth onChange={(event) => {updateTomador('tipoPessoa', event.target.value)}}>
                      <MenuItem value={"PF"} selected>Pessoa Física</MenuItem>
                      <MenuItem value={"PJ"} >Pessoa Juridica</MenuItem>
                    </Select>
                  </Grid>


                  <Grid item xs={4}>
                      <div>CPF / CNPJ</div>
                        <TextField name='cpfcnpj' fullWidth 
                        value={tomadorValues.pessoa.cpfCnpj} 
                        onChange={(event) => updateTomador("cpfCnpj", removerCaracteresNaoNumericos(event.target.value))} />
                  </Grid>

                  <Grid item xs={4}>
                      <div>Nome / Razão Social</div>
                        <TextField name='nomeRazaoSocial' fullWidth 
                        value={tomadorValues.pessoa.nomeRazaoSocial} 
                        onChange={(event) => updateTomador("nomeRazaoSocial", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Logradouro</div>
                        <TextField name='logradouro' fullWidth 
                        value={tomadorValues.pessoa.logradouro} onChange={(event) => updateTomador("logradouro", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Numero</div>
                        <TextField name='numero' fullWidth 
                        value={tomadorValues.pessoa.numero} onChange={(event) => updateTomador("numero", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Complemento</div>
                        <TextField name='complemento' fullWidth 
                        value={tomadorValues.pessoa.complemento} onChange={(event) => updateTomador("complemento", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Bairro</div>
                        <TextField name='bairro' fullWidth 
                        value={tomadorValues.pessoa.bairro} onChange={(event) => updateTomador("bairro", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>UF</div>
                        <TextField name='uf' fullWidth 
                        value={tomadorValues.pessoa.uf} onChange={(event) => updateTomador("uf", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Municipio</div>
                        <TextField name='municipio' fullWidth 
                        value={tomadorValues.pessoa.municipio} onChange={(event) => updateTomador("municipio", event.target.value)} />
                  </Grid>

                  <Grid item xs={4}>
                      <div>Cep</div>
                        <TextField name='codPostal' fullWidth 
                        value={tomadorValues.pessoa.codPostal} 
                        onChange={(event) => updateTomador("codPostal", removerCaracteresNaoNumericos(event.target.value))} />
                  </Grid>

                  <Grid item xs={4}>
                    <div>Grau de Exposição Política</div>
                    <Select id="grauExposicaoPolitica" value={tomadorValues.pessoa.grauExposicaoPolitica} fullWidth onChange={(event) => {updateTomador('grauExposicaoPolitica', event.target.value)}}>
                      <MenuItem value={"1"} >Não Exposto</MenuItem>
                      <MenuItem value={"2"} >Pessoa Politica Exposta</MenuItem>
                      <MenuItem value={"3"} >Pessoa Proxima a pessoa politica exposta</MenuItem>
                    </Select>
                  </Grid>                  
                  <Grid item xs={4}>
                      <div>Email</div>
                        <TextField name='email' fullWidth 
                        value={tomadorValues.pessoa.email} onChange={(event) => updateTomador("email", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Numero Doc Extrangeiro</div>
                        <TextField name='numeroDocExtrangeiro' fullWidth 
                        value={tomadorValues.pessoa.numeroDocExtrangeiro} onChange={(event) => updateTomador("numeroDocExtrangeiro", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                    <div>País de Residencia</div>
                    <Select id="paisResidencia" value={tomadorValues.pessoa.paisResidencia} fullWidth onChange={(event) => {updateTomador('paisResidencia', event.target.value)}}>
                      <MenuItem value={"1"} >Brasil</MenuItem>
                    </Select>
                  </Grid>                  



                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls='panel1-content'
                id="panel1-header">
                  <Typography>
                  <b>Segurado</b>
                  </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <div>Nacionalidade</div>
                    <Select id="nacionalidade" value={seguradoValues.pessoa.nacionalidade} fullWidth onChange={(event) => {updateSegurado('nacionalidade', event.target.value)}}>
                      <MenuItem value={"S"} selected>Brasileiro</MenuItem>
                      <MenuItem value={"N"} >Extrangeiro</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <div>Tipo de Pessoa</div>
                    <Select id="tipoPessoa" value={seguradoValues.pessoa.tipoPessoa}  fullWidth onChange={(event) => {updateSegurado('tipoPessoa', event.target.value)}}>
                      <MenuItem value={"PF"} selected>Pessoa Física</MenuItem>
                      <MenuItem value={"PJ"} >Pessoa Juridica</MenuItem>
                    </Select>
                  </Grid>


                  <Grid item xs={4}>
                      <div>CPF / CNPJ</div>
                        <TextField name='cpfcnpj' fullWidth 
                        value={seguradoValues.pessoa.cpfCnpj} onChange={(event) => updateSegurado("cpfCnpj", removerCaracteresNaoNumericos(event.target.value))} />
                  </Grid>

                  <Grid item xs={4}>
                      <div>Nome / Razão Social</div>
                        <TextField name='nomeRazaoSocial' fullWidth 
                        value={seguradoValues.pessoa.nomeRazaoSocial} onChange={(event) => updateSegurado("nomeRazaoSocial", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Logradouro</div>
                        <TextField name='logradouro' fullWidth 
                        value={seguradoValues.pessoa.logradouro} onChange={(event) => updateSegurado("logradouro", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Numero</div>
                        <TextField name='numero' fullWidth 
                        value={seguradoValues.pessoa.numero} onChange={(event) => updateSegurado("numero", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Complemento</div>
                        <TextField name='complemento' fullWidth 
                        value={seguradoValues.pessoa.complemento} onChange={(event) => updateSegurado("complemento", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Bairro</div>
                        <TextField name='bairro' fullWidth 
                        value={seguradoValues.pessoa.bairro} onChange={(event) => updateSegurado("bairro", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>UF</div>
                        <TextField name='uf' fullWidth 
                        value={seguradoValues.pessoa.uf} onChange={(event) => updateSegurado("uf", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Municipio</div>
                        <TextField name='municipio' fullWidth 
                        value={seguradoValues.pessoa.municipio} onChange={(event) => updateSegurado("municipio", event.target.value)} />
                  </Grid>

                  <Grid item xs={4}>
                      <div>Cep</div>
                        <TextField name='codPostal' fullWidth 
                        value={seguradoValues.pessoa.codPostal} 
                        onChange={(event) => updateSegurado("codPostal", removerCaracteresNaoNumericos(event.target.value))} />
                  </Grid>

                  <Grid item xs={4}>
                    <div>Grau de Exposição Política</div>
                    <Select id="grauExposicaoPolitica" value={seguradoValues.pessoa.grauExposicaoPolitica}  fullWidth onChange={(event) => {updateSegurado('grauExposicaoPolitica', event.target.value)}}>
                      <MenuItem value={"1"} >Não Exposto</MenuItem>
                      <MenuItem value={"2"} >Pessoa Politica Exposta</MenuItem>
                      <MenuItem value={"3"} >Pessoa Proxima a pessoa politica exposta</MenuItem>
                    </Select>
                  </Grid>                  
                  <Grid item xs={4}>
                      <div>Email</div>
                        <TextField name='email' fullWidth 
                        value={seguradoValues.pessoa.email} onChange={(event) => updateSegurado("email", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                      <div>Numero Doc Extrangeiro</div>
                        <TextField name='numeroDocExtrangeiro' fullWidth 
                        value={seguradoValues.pessoa.numeroDocExtrangeiro} onChange={(event) => updateSegurado("numeroDocExtrangeiro", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                    <div>País de Residencia</div>
                    <Select id="paisResidencia" value={seguradoValues.pessoa.paisResidencia}  fullWidth onChange={(event) => {updateSegurado('paisResidencia', event.target.value)}}>
                      <MenuItem value={"1"} >Brasil</MenuItem>
                    </Select>
                  </Grid>                  



                </Grid>

              </AccordionDetails>
            </Accordion>            
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls='panel1-content'
                id="panel1-header">
                  <Typography>
                  <b>Objeto</b>
                  </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <div>Tipo do Ojeto Segurado</div>
                    <Select id="tipoObjetoSegurado" value={objetoValues.objeto.tipoObjetoSegurado}  fullWidth onChange={(event) => {updateObjeto('tipoObjetoSegurado', event.target.value)}}>
                      <MenuItem value={"1"} >Contrato</MenuItem>
                      <MenuItem value={"2"} >Processo Administrativo</MenuItem>
                      <MenuItem value={"3"} >Processo Judicial</MenuItem>
                      <MenuItem value={"4"} >Contrato de Locação</MenuItem>
                      <MenuItem value={"6"} >Contrato de Financiamento</MenuItem>
                      <MenuItem value={"9"} >Pessoas</MenuItem>
                      <MenuItem value={"99"} >Outros</MenuItem>

                    </Select>
                  </Grid>  
                  {/**
                   * 
                  <Grid item xs={4}>
                      <div>Descrição do Tipo de Objeto</div>
                        <TextField name='descTipoObjeto' fullWidth 
                        value={objetoValues.objeto.descTipoObjeto}
                        onChange={(event) => updateObjeto("descTipoObjeto", event.target.value)} />
                  </Grid>
                  */}
                  <Grid item xs={4}>
                    <div>Origem do Risco</div>
                    <Select id="origemRisco" value={objetoValues.objeto.origemRisco} fullWidth onChange={(event) => {updateObjeto('origemRisco', event.target.value)}}>
                      <MenuItem value={"1"} >Brasil</MenuItem>
                      <MenuItem value={"2"} >Exterior</MenuItem>
                      <MenuItem value={"3"} >Brasil e Exterior</MenuItem>
                    </Select>
                  </Grid>

                    <Grid item xs={12}>
                      <div>Descrição do Objeto</div>
                        <TextField name='descricaoObjeto' fullWidth multiline rows={10}
                        value={objetoValues.objeto.descricaoObjeto}
                        onChange={(event) => updateObjeto("descricaoObjeto", event.target.value)} 
                        helperText={`${objetoValues.objeto.descricaoObjeto.length}/1024`}
                        />

                  </Grid>  

                </Grid>
              </AccordionDetails>
            </Accordion> 
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls='panel1-content'
                id="panel1-header">
                  <Typography>
                  <b>Cobertura</b>
                  </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                      <div>Numero Processo Susep</div>
                        <TextField name='numeroProcessoSusep' fullWidth 
                        value={coberturaValues.cobertura.numeroProcessoSusep}
                        onChange={(event) => updateCobertura("numeroProcessoSusep", event.target.value)} />
                  </Grid>
                  <Grid item xs={4}>
                    <div>Ramo</div>
                    <Select id="ramo" value={coberturaValues.cobertura.ramo} fullWidth 
                       onChange={(event) => {updateCobertura('ramo', event.target.value)}}>
                    <MenuItem value={"0711"}>Riscos Diversos -Financeiros</MenuItem>
                    <MenuItem value={"0739"} >Garantia Financeira (Ramo em Run Off)</MenuItem>
                    <MenuItem value={"0740"} >Garantia de Obrigações Privadas (Ramo em Run Off)</MenuItem>
                    <MenuItem value={"0743"} >Stop Loss</MenuItem>
                    <MenuItem value={"0745"} >Garantia de Obrigações Públicas (Ramo em Run Off)</MenuItem>
                    <MenuItem value={"0746"} >Fiança Locatícia</MenuItem>
                    <MenuItem value={"0747"} >Garantia de Concessões Públicas (Ramo em Run Off)</MenuItem>
                    <MenuItem value={"0748"} >Crédito Interno</MenuItem>
                    <MenuItem value={"0749"} >Crédito à Exportação</MenuItem>
                    <MenuItem value={"0750"} >Garantia Judicial (Ramo em Run Off)</MenuItem>
                    <MenuItem value={"0775"} >Garantia Segurado - Setor Público</MenuItem>
                    <MenuItem value={"0776"} >Garantia Segurado - Setor Privado</MenuItem>
                    </Select>
                  </Grid>  
                  <Grid item xs={4}>
                    <div>Base da Idenização</div>
                    <Select id="baseIdenizacao" value={coberturaAdicionalValues.coberturaAdicional.baseIdenizacao} fullWidth 
                      onChange={(event) => {updateCoberturaAdicional('baseIdenizacao', event.target.value)}}>
                      <MenuItem value={"1"} >Por Ocorrência</MenuItem>
                      <MenuItem value={"2"} >Por Reclamação</MenuItem>
                     {/** <MenuItem value={"99"} >Outra</MenuItem> */}
                    </Select>
                  </Grid>  


                  <Grid item xs={4}>
                    <div>Modalidade Principal</div>
                    <Select ref={codigoCoberturaRef} id="codigoCobertura" value={coberturaValues.cobertura.codigoCobertura} fullWidth 
                       onChange={(event) => {updateCodigoCobertura(event.target.value)}}>
                      {codigosCoberturaList.map((item) => 
                        (<MenuItem key={item.codigo} value={item.codigo} >{item.valor}</MenuItem>)
                      )}
                    </Select>
                  </Grid>  

                  <Grid item xs={4}>
                      <div>Limite Máximo Idenização</div>
                        <TextField name='limiteMaximoIdenizacao' 
                          value={coberturaValues.cobertura.limiteMaximoIdenizacao} fullWidth onChange={(event) => setLimiteMaximoCobertura(event.target.value)} />
                  </Grid>

                  <Grid item xs={4}>
                      <div>Prêmio Cobertura</div>
                        <TextField name='premioCobertura' 
                        value={coberturaValues.cobertura.premioCobertura} fullWidth 
                        onChange={(event) => updateCobertura("premioCobertura", event.target.value)} />
                  </Grid>
                  <Grid item xs={12}>&nbsp;</Grid>
                  <Grid item  xs={12}>
                  <Box display="flex"  alignItems="center" justifyContent="center" >
                  <Card variant='outlined' style={{width: "95%"}}>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Coberturas Adicionais 
                      </Typography>
                      <Grid container spacing={3}>
                      
                        {opcoesCoberturasAdicionais.map((cobertura, index) => (
                          
                          <Grid container key={index} spacing={3} padding={1}>
                          <Grid item xs={4} >
                          <Box display="flex"  alignItems="center" justifyContent="flex-end" style={{height: "100%"}}>
                            <FormControlLabel
                              control={<Checkbox checked={cobertura.checked} 
                                        onChange={(event) => updateCheckAdicional(cobertura, event.target.checked)}
                              />}
                              label={cobertura.descricaoBaseIdenizacao}
                              labelPlacement="start"
                              
                            />
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            
                              <TextField 
                              value={cobertura.descricaoBaseIdenizacao} fullWidth onChange={(event) => updateFieldAdicional(cobertura, "descricaoBaseIdenizacao", event.target.value)} />
                          </Grid>
                          <Grid item xs={4}>
                            
                              <TextField placeholder="Prêmio Adicional"
                              value={cobertura.valorPremioAdicional} fullWidth onChange={(event) => updateFieldAdicional(cobertura, "valorPremioAdicional", event.target.value)} />
                          </Grid>

                          </Grid>
                        ))}
                      
                  </Grid>
                  </CardContent>
                  </Card>
                  </Box>
                  </Grid>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={4}>
                    <div>Prêmio Total</div>
                        <TextField 
                        value={caracteristicasValues.caracteristicas.valorTotalPremio} fullWidth  />

                  </Grid>




                </Grid>
              </AccordionDetails>
            </Accordion>          
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls='panel1-content'
                id="panel1-header">
                  <Typography>
                  <b>Prêmio</b>
                  </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={3}>
                
                <Grid item xs={4}>
                  <div>Quantidade de Parcelas</div>
                  <Select id="qtdeParcelas" value={premiosValues.premios.qtdeParcelas} fullWidth 
                    onChange={(event) => updateQuantidadeParcelas(event)} >
                    <MenuItem value={"1"} >1 X</MenuItem>
                    <MenuItem value={"2"} >2 X</MenuItem>
                    <MenuItem value={"3"} >3 X</MenuItem>
                    <MenuItem value={"4"} >4 X</MenuItem>

                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <div>Vencimento Inicial</div>
                  <DateTimePicker id="dataVencimentoInicial" views={['year', 'month', 'day' ]} 
                        value={dayjs(premiosValues.premios.dataVencimentoInicial)} onChange={(newValue) => setFieldData(PREMIOSCOLECTION ,"dataVencimentoInicial",newValue)}  slotProps={{ textField: { fullWidth: true } }} />
                </Grid>
                

                <Grid item xs={12}>
                {premiosValues.premios.parcelas.map((parcela, index) => (
                  <Grid container spacing={3} key={index}>
                    <Grid item xs={4}>
                      <div>Parcela</div>
                      <TextField fullWidth disabled value={parcela.premio.parcela}  />
                    </Grid>
                    <Grid item xs={4}>
                      <div>Vencimento</div>
                      <DateTimePicker views={['year', 'month', 'day' ]} 
                        value={dayjs(parcela.premio.dataVencimento)} onChange={(newValue) => setVencimentoParcela(newValue, index)}  
                        slotProps={{ textField: { fullWidth: true } }} />
                    </Grid>
                    <Grid item xs={4}>
                      <div>Valor</div>
                      <TextField fullWidth value={parcela.premio.valor} id={"parcela".concat(index)}
                      disabled
                      onChange={e => setValorParcela(e.target.value, index)}/>
                    </Grid>

                  </Grid>
              ))}
              </Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={4}>
                  <div>Valor Total</div>
                    <TextField name='valor' fullWidth disabled
                    value={premiosValues.premios.valorTotalParcelas}
                    onChange={(event) => updatePremios("valorTotalParcelas", event.target.value)} />
                </Grid>
              </Grid>

              </AccordionDetails>
            </Accordion>              
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" onClick={salvarApolice} > 
              Salvar
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" onClick={clonarApolice} > 
              Clonar
            </Button>
          </Grid>

          <Grid item xs={3}>
            <Button fullWidth variant="contained" onClick={criarArquivo} > 
              Criar Arquivo
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained"  onClick={criarMinuta} > 
              Criar Minuta
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" onClick={criarApolice} > 
              Criar Apolice
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="contained" onClick={criarEndosso} > 
              Criar Endosso
            </Button>
          </Grid>


          <Grid item xs={12}>
            <div>Arquivo</div>
            <TextField fullWidth multiline minRows={10} value={arquivo} />

          </Grid>
        </Grid>
        )}
        
    </Container>
  );
}
