import { Navigate, useRoutes } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import RenewPasswordPage from './pages/RenewPasswordPage';
import LogoutPage from './pages/LogoutPage';
import CreateClientPage from './pages/CreateClientPage';
import { AppConstants } from './context/configs/constants';
import ReportsPage from './pages/ReportsPage';
import GlobalPage from 'pages/GlobalPage';
import CreateApolicePage from 'pages/CreateApolicePage';
import Homolog from 'pages/Homolog';
// layouts

export default function Router() {

  const routes = useRoutes([
    {
      path: AppConstants.routePaths.dashboard,
      element: <DashboardPage />,
      children: [
        { element: <Navigate to="/dashboard/global" />, index: true },
        { path: 'global', element: <GlobalPage /> },
        { path: 'apolicenew', element: <CreateApolicePage /> },
        { path: 'apoliceedit/:id', element: <CreateApolicePage /> },
      ],
    },
    {
      path: AppConstants.routePaths.homolog,
      element: <Homolog />,
    },

    {
      path: AppConstants.routePaths.logout,
      element: <LogoutPage />,
    },
    {
      path: AppConstants.routePaths.apolicenew,
      element: <CreateApolicePage />
    },
    {
      path: AppConstants.routePaths.login,
      element: <LoginPage />,
    },
    {
      path: AppConstants.routePaths.resetPassword,
      element: <ResetPasswordPage />,
    },
    {
      path: AppConstants.routePaths.renewPassword,
      element: <RenewPasswordPage />,
    },
    {
      path: AppConstants.routePaths.createUser,
      element: <CreateClientPage />,
    },
    {
      path: AppConstants.routePaths.reports,
      element: <ReportsPage />,
    },
    
    
    {
      path: '',
      element: <LoginPage />,
    },
    {
      path: '*',
      element: <DashboardPage />,
    },

    

  ]);

  return routes;
}

