import axios from 'axios';

var adicionalHeaders = [];
//adicionalHeaders['Origin'] = document.location.protocol.concat("//", document.location.host);


export const backpreferences = async () => {

    return {};
    /*
	return axios.get('/preferences').then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
    */
};


export const backUserLogin = async ({email, password}) => {
	const params = {
		email,
		password
	};
    
	return axios.post('/authenticate', { ...params },{headers: { ...adicionalHeaders}}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};

export const backUserResetPassword = async ({email}) => {
	const params = {
		email
	};
    
    console.log({params});

	return axios.post('/resetpassword', { ...params }, {headers: {...adicionalHeaders}}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};

export const backUserValidateToken = async (token) => {
	const params = {
		token
	};
    
    console.log({params});

	return axios.get('/validate'.concat('?token=', token)).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        console.log(erro);
        return erro;
    });
};


export const backUserRenewPassword = async ({email, password, newpassword, confirmpassword}) => {
	const params = {
		email, password, newpassword, confirmpassword
	};
    
    console.log({params});

	return axios.post('/renewpassword', { ...params }, {headers: { ...adicionalHeaders}}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};


