import { useContext, useEffect, useState } from 'react';
// @mui
import {  IconButton, InputAdornment, Link, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import { AppConstants } from '../../../context/configs/constants';

// ----------------------------------------------------------------------

export default function RenewPasswordForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  

  const [msg, setMsg] = useState('');
  const {user, BackUserFunctions} = useContext(AppContext);
  

  const handleClick = () => {
    setLoading(true);
    BackUserFunctions.renewPassword(email, password, newpassword, confirmpassword).then(resp => {
      setMsg(resp.msg)
      if (resp.validate) {
        setEmail("");
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
      setLoading(false);
    });
    
    
  };

  useEffect(() => {
    if (Boolean(user.loginOk)) {
      navigate('/dashboard', { replace: true });
    }

  }, [navigate, user]);

  return (
      <>
        <Stack spacing={3}>
          <TextField name="email" label="Email do Usuário" value={email} onChange={(e) => setEmail(e.target.value)} />

          <TextField
            name="password"
            label="Senha"
            value={password} 
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton  
                  onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            name="newpassword"
            label="Nova Senha"
            value={newpassword} 
            onChange={(e) => setNewPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton 
                  onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            name="confirmpassword"
            label="Senha"
            value={confirmpassword} 
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton 
                  onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="right" sx={{ my: 1 }}>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} loading={loading} loadingIndicator="Processando…">
          Renovar Senha
        </LoadingButton >

        <Stack direction="row" alignItems="center" justifyContent="right" sx={{ my: 1 }}>
          <Link variant="subtitle2" underline="hover" href={AppConstants.routePaths.login}>
            Voltar ao Login
          </Link>
        </Stack>

        <Stack justifyContent="center" sx={{ my: 1 }} >
          {msg}
        </Stack>


      </>
  );
}
