import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loading from '../components/loading';
import { AppContext } from '../context/AppContext';
import { Helmet } from 'react-helmet-async';
import Enviroment from '../context/configs/enviroment';
import { AppConstants } from '../context/configs/constants';




export default function ContainerDashboardPage(props) {
  const navigate = useNavigate();
  const {title} = props;

  const {user, BackAppFunctions} = useContext(AppContext);

  

  useEffect(() => {
    const path = document.location.pathname;
    const pathsOpen = Enviroment.openPaths;

    if (user.hasOwnProperty("localValidate")) { //ainda não validou o lead
      BackAppFunctions.GetPreferences();
    } else if (path.valueOf() === "/login") {
      if (Boolean(user.authenticate)) {
        navigate('/dashboard', { replace: true });
      }
    } else {
        // se o path deve ser seguro
        if (!pathsOpen.includes(path)){
          if (!Boolean(user.authenticate)) {
            navigate('/login', { replace: true });
          }
        }
        
    }

  }, [navigate, user, BackAppFunctions]);


  
  if (Boolean(user.hasOwnProperty("localValidate"))) {
    return (
    <>
      <Helmet>
        <title> {AppConstants.nameApp} </title>
      </Helmet>


      
      <Loading />
    
    </>) ;
  } else {
    return (
      <>
        <Helmet>
          <title> {title} | {AppConstants.nameApp} </title>
        </Helmet>

          {props.children}
      </>
      );
  }
    
}

