import { useContext, useEffect } from "react";
import { AppContext } from '../context/AppContext';
import ContainerPage from "./ContainerPage";
import { useNavigate } from "react-router-dom";
import StyledContent from "components/styledContent/StyledContent";
import { CircularProgress } from "@mui/material";

export default function LogoutPage() {
    const navigate = useNavigate();
    const {BackAppFunctions} = useContext(AppContext);

    useEffect(() => {
        localStorage.removeItem("token");
        BackAppFunctions.Logout().then((resp => {
            navigate('/', { replace: true });
        }));

    }, [navigate, BackAppFunctions])


    return <ContainerPage title="Sair" >
        <StyledContent>
            <CircularProgress />
            Saindo ...
        </StyledContent>

    </ContainerPage>;
}



