import { useContext, useEffect, useState } from 'react';
// @mui
import {  Link, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import { AppConstants } from '../../../context/configs/constants';

// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const {user, BackUserFunctions} = useContext(AppContext);
  const [loading, setLoading] = useState(false);


  const handleClick = () => {
    setLoading(true);
    BackUserFunctions.resetPassword(email).then(resp => {
      setMsg(resp.msg)
      if (resp.validate) {
        setEmail("");
      }
      setLoading(false);
    });
    
    
  };

  useEffect(() => {
    if (Boolean(user.loginOk)) {
      navigate('/dashboard', { replace: true });
    }

  }, [navigate, user]);

  return (
      <>
        <Stack spacing={3}>
          <TextField name="email" label="Email do Usuário" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="right" sx={{ my: 1 }}>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} loading={loading} loadingIndicator="Processando…">
          Resetar
        </LoadingButton >

        <Stack direction="row" alignItems="center" justifyContent="right" sx={{ my: 1 }}>
          <Link variant="subtitle2" underline="hover" href={AppConstants.routePaths.login}>
            Voltar ao Login
          </Link>
        </Stack>

        <Stack justifyContent="center" sx={{ my: 1 }} >
          {msg}
        </Stack>


      </>
  );
}
