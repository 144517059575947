import axios from 'axios';

export const backSaveApolice = async ({token, apolice}) => {
    
	return axios.post('/saveapolice', { token, apolice },{}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};


export const backSaveApoliceTXT = async ({token, apolice, dadosTXT}) => {
    
	return axios.post('/saveapolicetxt', { token, apolice, dadosTXT },{}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};



export const backCreateApolice = async ({token, apolice}) => {
    
	return axios.post('/createfileapolice', { token, apolice },{}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};

export const backCreateApoliceNew = async ({token, apolice}) => {
    
	return axios.post('/createfileapolicenew', { token, apolice },{}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};

export const backCreateMinuta = async ({token, apolice}) => {
    
	return axios.post('/createfileminuta', { token, apolice },{}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};

export const backCreateMinutaNew = async ({token, apolice}) => {
    
	return axios.post('/createfileminutanew', { token, apolice },{}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};


export const backLoadApolice = async ({token, apoliceid}) => {
    
	return axios.get('/loadapolice'.concat('?token=', token).concat("&apoliceid=", apoliceid),{}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};

export const backListApolices = async ({token}) => {
	return axios.get('/listapolices'.concat('?token=', token),{}).then((response) => {
        const data = response.data;
        return data;
    }).catch(erro => {
        return erro;
    });
};
