import { Container, Typography } from '@mui/material';
import { ResetPasswordForm } from '../sections/users/resetpassword';
import ContainerPage from './ContainerPage';
import StyledContent from '../components/styledContent';


export default function ResetPasswordPage() {

  return (
    <ContainerPage title="Redefinir Senha">
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Redefina sua senha
            </Typography>
            <ResetPasswordForm />
          </StyledContent>
        </Container>
    </ContainerPage>
  );
}
