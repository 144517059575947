// component

import { CurrencyExchange, InsertChart, Logout } from "@mui/icons-material";
// ----------------------------------------------------------------------


const navConfig = [
  {
    title: 'Gestão de Apólices',
    path: '/dashboard/global',
    icon: <InsertChart />,
  },
  {
    title: 'Criar Nova',
    path: '/dashboard/apolicenew',
    icon: <CurrencyExchange />,
  },
  {
    title: 'Sair',
    path: '/logout',
    icon: <Logout />,
  },
];

export default navConfig;
