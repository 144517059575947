import { useContext, useState } from 'react';
// @mui
import {  Link, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { AppContext } from '../../context/AppContext';
import { AppConstants } from '../../context/configs/constants';

// ----------------------------------------------------------------------

export default function CreateUserForm() {

  const [companyName, setCompanyName] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [cpfCnpjError, setCpfCnpjError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const [cpfCnpjErrorMsg, setCpfErrorMsg] = useState("");  
  const [emailErrorMsg, setEmailErrorMsg] = useState("");  
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");  


  const [msg, setMsg] = useState({msgs: {success: {msg: ""}}});
  const {BackAppFunctions} = useContext(AppContext);
  

  const handleClick = () => {
    BackAppFunctions.createClient(companyName, cpfCnpj, name, email, phone).then(resp => {
      setMsg(resp);
      console.log(resp);
      setCpfCnpjError(!resp.validateCpfCnpj);
      setEmailError(!resp.validateEmail);
      setPhoneError(!resp.validatePhone);

      setCpfErrorMsg(resp.msgs.cpfCnpj.msg);
      setEmailErrorMsg(resp.msgs.email.msg);
      setPhoneErrorMsg(resp.msgs.phone.msg);
      if (resp.validate) {
        setName("");
        setCpfCnpj("");
        setCompanyName("");
        setEmail("");
        setPhone("");

      }
    });
  };


  return (
      <>
        <Stack spacing={3}>
          <TextField name="companyName" label="Nome da Empresa" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
          <TextField name="cpfCnpj" label="CPF do Titular ou CNPJ da Empresa " value={cpfCnpj} onChange={(e) => setCpfCnpj(e.target.value)} error={cpfCnpjError} helperText={cpfCnpjErrorMsg} />
          <TextField name="name" label="Nome do Usuário" value={name} onChange={(e) => setName(e.target.value)} />
          <TextField name="email" label="Email do Usuário" value={email} onChange={(e) => setEmail(e.target.value)}  error={emailError} helperText={emailErrorMsg} />
          <TextField name="phone" label="Whatsapp do Usuário" value={phone} onChange={(e) => setPhone(e.target.value)} error={phoneError} helperText={phoneErrorMsg} />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="right" sx={{ my: 1 }}>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
          Criar Clinica
        </LoadingButton >

        <Stack direction="row" alignItems="center" justifyContent="right" sx={{ my: 1 }}>
          <Link variant="subtitle2" underline="hover" href={AppConstants.routePaths.login}>
            Voltar ao Login
          </Link>
        </Stack>

        <Stack justifyContent="center" sx={{ my: 1 }} >
          {msg.msgs.success.msg}
        </Stack>


      </>
  );
}
