// @mui
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  })); 

export default StyledRoot;