
export const defaultLinksUserOff = [
    {label: "Login", type: 'navigate', url: "/login"}
];
export const defaultLinksUserOn = [
    {label: "Sair", type: 'navigate', url: "/logout"},
    {label: "Alterar Senha", type: 'navigate', url: "/renewpassword"}
];

export const defaultLinksFunctionsOff = [
    {label: 'Preços', type: 'link', url: "/precos"}, 
    {label: 'Blog', type: 'link', url: "/blog"}, 
];

export const defaultLinksFuntionsOn = [
    {label: 'Relatórios', type: 'navigate', url: "/reports"}, 
]


export const initialLinksBar = {
    functionLinks : defaultLinksFunctionsOff,
    userLinks : defaultLinksUserOff, 
}

export const AppConstants = {
    nameApp: "Apolice App",

    routePaths : {
        defaultPath : "/dashboard",
        login : "/login",
        logout: "/logout",
        renewPassword : "/renewpassword",
        resetPassword : "/resetpassword",
        createUser : "/createclient",
        dashboard : "/dashboard",
        apolicenew: "/apolicenew",
        homolog: "/homolog",

        reports: "/reports"
        
    }
}

export const contaSeguradora = '53427.30-0';

export const InitialApoliceRegapHeader = {
    header : {
        funcao : 'REGAP',
        tipoRegistro: 0,
        tipoDoc: "MINUTA",
        tituloDoc: "",
        acao: 'INCL',
        nomeParticipante : 'INFINITESEG',
        contaSeguradora: contaSeguradora,
        data: "",
        versaoLayout: "5",
        motivoAlteracao: "",
        idInterno: ""
    }, criarRegHeaderTxt : (reg) => {
            var retorno = "";
            retorno += reg.funcao + "";
            retorno += reg.tipoRegistro + "";
            retorno += reg.acao + ";";
            retorno += reg.nomeParticipante + ";";
            retorno += reg.contaSeguradora + ";";
            retorno += reg.data + ";";
            retorno += reg.versaoLayout + "";
            return retorno;
    },
    
    criarRegMotivoAlteracao : (reg) => {
        var retorno = "";
        retorno += reg.funcao + ";";
        retorno += "B;";
        retorno += reg.acao + ";";
        retorno += reg.motivoAlteracao + "";
        return retorno;

    }


}

export const InitialApoliceCaracteristicasGerais = {
    caracteristicas: {
        funcao: 'REGAP',
        tipoRegistro: 1,
        acao: "INCL",
        contaSeguradora: InitialApoliceRegapHeader.header.contaSeguradora,
        numeroApolice : "",
        codigoSusep: "",
        dataEmissao: "",
        dataInicioVigencia: "",
        dataTerminoVigencia: "",
        tipoDocumento: '1', // 1-  Emissão Própria | 2 - Coseguro Aceito
        tipoContratacao: '1',  // 1 - individual | 2 - Coletiva ... 
        sucursal: 1, // Codigo da Sucursal  - 1 (Matriz)
        descSucursal: "Goiânia",
        ufSucursal: 'GO',
        valorPremioCoseguroRetido: "",
        valorTotalPremioCoseguro: "",
        valorTotalPremio: "",
        valorTotalPremioComercial: "",
        custoAquisicaoTotal: "",
        custoAquisicaoRedutorTotal: "",
        adicionalFracionamentoTotal: "",
        custoInicialContratacaoTotal: "",
        iofTotal: "",
        moedaEmissao: "BRL",
        cotacao: "",
        limiteMaximoGarantia: "",
        numeroApoliceDeLider: "",
        codigoSusepLider: "",
        tipoBloqueio: "",
        descricaoBloqueio: "",
        descricaoAdicional: "",
        valorPremioInicial: "",
        valorDeposito: "",
        valorPremiAjuste: "",
        limiteCredito: "",
        tipoLimite: "",
        descTipoLimite: "",
        tipoCoberturaBasica: "",
        RamoPrincipalApolice: "",
        nomeCarteira: ""
    }, criarRegCaracteristicasGeraisTxt : (reg) => {
        console.log({caract: reg});
        var retorno = "";
        retorno += reg.funcao + ";";
        retorno += reg.tipoRegistro + ";";
        retorno += reg.acao + ";";
        retorno += reg.contaSeguradora + ";";
        retorno += reg.numeroApolice + ";";
        retorno += reg.codigoSusep + ";";
        retorno += reg.dataEmissao + ";";
        retorno += reg.dataInicioVigencia + ";";
        retorno += reg.dataTerminoVigencia + ";";
        retorno += reg.tipoDocumento + ";";
        retorno += reg.tipoContratacao + ";";
        retorno += reg.sucursal + ";";
        retorno += reg.ufSucursal + ";";
        retorno += reg.valorPremioCoseguroRetido + ";";
        retorno += reg.valorTotalPremioCoseguro + ";";
        retorno += reg.valorTotalPremio + ";";    
        retorno += reg.valorTotalPremioComercial + ";";
        retorno += reg.custoAquisicaoTotal + ";";
        retorno += reg.custoAquisicaoRedutorTotal + ";";
        retorno += reg.adicionalFracionamentoTotal + ";";
        retorno += reg.custoInicialContratacaoTotal + ";";
        retorno += reg.iofTotal + ";";
        retorno += reg.moedaEmissao + ";";
        retorno += reg.cotacao + ";";
        retorno += reg.limiteMaximoGarantia + ";";
        retorno += reg.numeroApoliceDeLider + ";";
        retorno += reg.codigoSusepLider + ";";
        retorno += reg.tipoBloqueio + ";";
        retorno += reg.descricaoBloqueio + ";";
        retorno += reg.descricaoAdicional + ";";
        retorno += reg.valorPremioInicial + ";";
        retorno += reg.valorDeposito + ";";
        retorno += reg.valorPremiAjuste + ";";
        retorno += reg.limiteCredito + ";";
        retorno += reg.tipoLimite + ";";
        retorno += reg.descTipoLimite + ";";
        retorno += reg.tipoCoberturaBasica + ";";
        retorno += reg.RamoPrincipalApolice + ";";
        retorno += reg.nomeCarteira + "";
        return retorno;
    }
};

export const InitialPessoa = {
    pessoa : {
        funcao: 'REGAP',
        tipoRegistro: 6,
        acao: "INCL",
        funcaoPessoa: "1", // 1 - Tomador | 2 Segurado
        nacionalidade: "S", // S - Brasileiro | N Extrangeiro
        tipoPessoa: "PJ", // PF OU PJ
        cpfCnpj: "",
        tipoPessoaJuridica: "", // S - Direito Público | N Direito privado
        nomeRazaoSocial: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        uf:  "",
        municipio: "",
        codPostal: "",
        grauExposicaoPolitica: "1", // 1 - Não Exposto | 2 - Pessoa Politica Exposta | 3 - Pessoa Proxima a pessoa politica exposta
        email: "",
        numeroDocExtrangeiro: "",
        paisResidencia: "1",
        dataNascimento: ""
    },
    criarRegPessoaTxt : (reg) => {
        console.log({caract: reg});
        var retorno = "";
        retorno += reg.funcao + ";";
        retorno += reg.tipoRegistro + ";";
        retorno += reg.acao + ";";
        retorno += reg.funcaoPessoa + ";";
        retorno += reg.nacionalidade + ";";
        retorno += reg.tipoPessoa + ";";
        retorno += reg.cpfCnpj + ";";
        retorno += reg.tipoPessoaJuridica + ";";
        retorno += reg.nomeRazaoSocial + ";";
        retorno += reg.logradouro + ";";
        retorno += reg.numero + ";";
        retorno += reg.complemento + ";";
        retorno += reg.bairro + ";";
        retorno += reg.uf + ";";
        retorno += reg.municipio + ";";
        retorno += reg.codPostal + ";";
        retorno += reg.grauExposicaoPolitica + ";";
        retorno += reg.email + ";";
        retorno += reg.numeroDocExtrangeiro + ";";
        retorno += reg.paisResidencia + ";";
        retorno += reg.dataNascimento + "";

        return retorno;
    }
}

export const InitialCobertura = {
    cobertura : {
        funcao: 'REGAP',
        tipoRegistro: "8",
        acao: "INCL",
        numeroProcessoSusep: "",
        ramo: "", // codigo da lista de ramos
        codigoCobertura: "", // codigo da lista de coberturas
        descricaoCobertura: "PRESTAÇÃO DE SERVIÇOS",
        limiteMaximoIdenizacao: "" , // x
        indicadorLMIEhSublimite: "",
        premioCobertura: "", // x
        dataEmissaoPremio: "", // x
        dataInicioVigencia: "", // x
        dataTerminoVigencia: "", // x
        adicionalFracionamento: "" ,
        custoInicialContratacao: "",
        custoAquisicao: "",
        custoAquisicaoRedutor: "",
        IOF: "",
        proLabore: "",
        tipoIndice: "",
        periodicidadeJuros: "",
        unidadeTempo: "",
        tipoPrazo: "",
        dataTerminoCarencia: "",
        codigoCoberturaSeguradora: "",
        indicadorCoberturaPrincipalObjeto: "",
        caracteristicaCobertura: "",
        tipoRisco: "",
        tipoCobertura: "",
        dataInicioCarencia: "",
        prazoCarencia: "",
        unidadeTempoCarencia: "",
        indicadorDiasUteisCarencia: "",
        dataInicioVigenciaPremio: "",
        dataTerminoVigenciaPremio: "",
        periodicidadePremio: "",
        descricaoPeriodicidadePremio: "",
        coberturasAdicionais : [],

    },
    criarRegCoberturaTxt : (reg) => {
        var retorno = ""; 
        retorno += reg.funcao + ";";
        retorno += reg.tipoRegistro + ";";
        retorno += reg.acao + ";";
        retorno += reg.numeroProcessoSusep + ";";
        retorno += reg.ramo + ";";
        retorno += reg.codigoCobertura + ";";
        retorno += reg.descricaoCobertura + ";";
        retorno += reg.limiteMaximoIdenizacao + ";";
        retorno += reg.indicadorLMIEhSublimite + ";";
        retorno += reg.premioCobertura + ";";
        retorno += reg.dataEmissaoPremio + ";";
        retorno += reg.dataInicioVigencia + ";";
        retorno += reg.dataTerminoVigencia + ";";
        retorno += reg.adicionalFracionamento + ";";
        retorno += reg.custoInicialContratacao + ";";
        retorno += reg.custoAquisicao + ";";
        retorno += reg.custoAquisicaoRedutor + ";";
        retorno += reg.IOF + ";";
        retorno += reg.proLabore + ";";
        retorno += reg.tipoIndice + ";";
        retorno += reg.periodicidadeJuros + ";";
        //retorno += reg.unidadeTempo + ";";
        //retorno += reg.tipoPrazo + ";";
        retorno += reg.dataTerminoCarencia + ";";
        retorno += reg.codigoCoberturaSeguradora + ";";
        retorno += reg.indicadorCoberturaPrincipalObjeto + ";";
        retorno += reg.caracteristicaCobertura + ";";
        retorno += reg.tipoRisco + ";";
        retorno += reg.tipoCobertura + ";";
        retorno += reg.dataInicioCarencia + ";";
        retorno += reg.prazoCarencia + ";";
        retorno += reg.unidadeTempoCarencia + ";";
        retorno += reg.indicadorDiasUteisCarencia + ";";
        retorno += reg.dataInicioVigenciaPremio + ";";
        retorno += reg.dataTerminoVigenciaPremio + ";";
        retorno += reg.periodicidadePremio + ";";
        retorno += reg.descricaoPeriodicidadePremio + "";

        if (reg.coberturasAdicionais) {
            for (let index = 0; index < reg.coberturasAdicionais.length; index++) {
                const item = reg.coberturasAdicionais[index];
                retorno += String.fromCharCode(13);
                retorno += InitialCoberturaAdicional.criarRegCoberturaAdicionalTxt(item);
                
            }
        }
        
        
        return retorno;
    }
}

export const InitialCoberturaAdicional = {
    coberturaAdicional : {
        funcao: 'REGAP',
        tipoRegistro: "E",
        acao: "INCL",
        baseIdenizacao: "2", // 1 - por ocorrencia | 2 - por reclamacao | 99 - Outra,
        
        codigoInterno: "",
        checked: false,
        descricaoTipoIdenizacao: "Adicional",
        descricaoBaseIdenizacao: "", // Descrever se a base for 99
        valoBaseAdicional: "", // Valor Maximo da Cobertura
        dataInicioVigencia: "",
        valorPremioAdicional: "",
        dataTerminoVigencia: "",            
    
        
        dataLimiteRetroatividade: "",
        prazoRetroatividade: "",
        unidadeTempoRetroatividade: "",
        indicadorDiasUteisRetroatividade: "",
        dataInicioPrazoAdicionalComplementar: "",
        dataFimPrazoAdicionalComplementar: "",
        prazoAdicionalComplementar: "",
        unidadePrazoAdicionalComplementar: "",
        dataInicioPrazoAdicionalSuplementar: "",
        dataFimPrazoAdicionalSuplementar: "",
        prazoAdicionalSuplementar: "",
        unidadePrazoAdicionalSuplementar: "",
        indicadorDiasUteisPrazoAdicionalSuplementar: "",
        indicacaoAdvogado: "",
        descricaoFormaIndicacaoAdvogado: "",
        coberturaASegundoRisco: "",
    },
    criarRegCoberturaAdicionalTxt : (reg) => {
        console.log({cobertura: reg});
        var retorno = ""; 
        retorno += reg.funcao + ";";
        retorno += reg.tipoRegistro + ";";
        retorno += reg.acao + ";";
        retorno += reg.baseIdenizacao + ";";
        retorno += reg.descricaoBaseIdenizacao + ";";
        retorno += reg.dataLimiteRetroatividade + ";";
        retorno += reg.prazoRetroatividade + ";";
        retorno += reg.unidadeTempoRetroatividade + ";";
        retorno += reg.indicadorDiasUteisRetroatividade + ";";
        retorno += reg.dataInicioPrazoAdicionalComplementar + ";";
        retorno += reg.dataFimPrazoAdicionalComplementar + ";";
        retorno += reg.prazoAdicionalComplementar + ";";
        retorno += reg.unidadePrazoAdicionalComplementar + ";";
        retorno += reg.dataInicioPrazoAdicionalSuplementar + ";";
        retorno += reg.dataFimPrazoAdicionalSuplementar + ";";
        retorno += reg.prazoAdicionalSuplementar + ";";
        retorno += reg.unidadePrazoAdicionalSuplementar + ";";
        retorno += reg.indicadorDiasUteisPrazoAdicionalSuplementar + ";";
        retorno += reg.indicacaoAdvogado + ";";
        retorno += reg.descricaoFormaIndicacaoAdvogado + ";";
        retorno += reg.coberturaASegundoRisco + ";";

        return retorno;

    }
}

export const InitialPremios = {
    premios: {
        parcelas: [],
        qtdeParcelas: 1,
        valorTotalParcelas: "",
        dataVencimentoInicial: "",
    },
    recalcularValorParcelas: (premiosBase) => {
        const valorTotalParcelas = parseFloat(premiosBase.premios.valorTotalParcelas);
        const valorParcela = valorTotalParcelas/premiosBase.premios.qtdeParcelas;
        var total = 0;
        premiosBase.premios.parcelas.map((parcela, index) => {
            var valor = valorParcela.toLocaleString('en-US', {maximumFractionDigits : 2}).replace(",", "");
            if ((premiosBase.premios.parcelas.length -1) === index) {
                console.log(total, valor);
                valor = valorTotalParcelas - total;
                valor = valor.toLocaleString('en-US', {maximumFractionDigits : 2}).replace(",", "");
            }
            total += parseFloat(valor);
            premiosBase.premios.parcelas[index].premio.valor = valor;
            return valorParcela;
        });
        return premiosBase;
    },
    criarRegPremiosTxt : (reg) => {
        var retorno = "" ;
        var ACAO = "INCL";
        if (reg.acao){
            ACAO = reg.acao;
        }
        
        reg.parcelas.map((item, i) => {
            retorno += InitialPremio.criarRegPremioTxt(item.premio, ACAO);
            if ((reg.parcelas.length -1) !== i ) retorno += String.fromCharCode(13);
            return true;
        });

        return retorno;

    }
}
export const InitialPremio = {
    premio : {
        funcao: 'REGAP',
        tipoRegistro: "9",
        acao: "INCL",
        parcela: "1", // penso que é o numero da parcela
        valor: "",
        moeda: "BRL",
        valorEmReais: "", // Só deve ser preenchido se o valor for em outra moeda diferente de BRL
        dataVencimento: "",


    }, 

    criarRegPremioTxt : (reg, ACAO) => {
        console.log({premio: reg});
        var retorno = ""; 
        retorno += reg.funcao + ";";
        retorno += reg.tipoRegistro + ";";
        retorno += (ACAO?ACAO:reg.acao) + ";";
        retorno += reg.parcela + ";";
        retorno += reg.valor + ";";
        retorno += reg.moeda + ";";
        retorno += reg.valorEmReais + ";";
        retorno += reg.dataVencimento + "";

        return retorno;
    }

}

export const InitialObjeto = {
    objeto : {
        funcao: 'REGAP',
        tipoRegistro: "7",
        acao: "INCL",
        tipoObjetoSegurado: "1", // 1 Contrato, 2 processo adm, 3 - processo judicial, 4 - contrato de locação, 6 - contrato de financiamento, 9 pessoas, 99 outros
        descTipoObjeto: "", 
        codigoOjeto: "",
        descricaoObjeto: "",
        origemRisco: "1", // 1 - Brasil / 2 - exterior/ 3 Brasil e Exterior
        ufRisco: "", 
        codigoPostal: "",
        Pais: "", // 1 - Brasil
        dataInicioContratoPrincial: "",
        dataTerminoContratoPrincipal: "",
        numeroIdentificacaoTipoObjeto: "",
        valorOjetoSegurado: ""


    },

    criarRegObjetoTxt : (reg) => {
        console.log({objeto: reg});
        var retorno = ""; 
        retorno += reg.funcao + ";";
        retorno += reg.tipoRegistro + ";";
        retorno += reg.acao + ";";
        retorno += reg.tipoObjetoSegurado + ";";
        retorno += reg.descTipoObjeto + ";";
        retorno += reg.codigoOjeto + ";";
        retorno += reg.descricaoObjeto.replace(/(\r\n|\n|\r)/gm, "").substring(0,1023) + ";";
        retorno += reg.origemRisco + ";";
        retorno += reg.ufRisco + ";";
        retorno += reg.codigoPostal + ";";
        retorno += reg.Pais + ";";
        retorno += reg.dataInicioContratoPrincial + ";";
        retorno += reg.dataTerminoContratoPrincipal + ";";
        retorno += reg.numeroIdentificacaoTipoObjeto + ";";
        retorno += reg.valorOjetoSegurado + "";

        return retorno;

    }
}

export const InitialCorretor = {
    corretor : {
        funcao: 'REGAP',
        tipoRegistro: "3",
        acao: "INCL",
        informarCorretor: "N",
        tipoItermediador: "1", // 1 - Corretor | 2 - Representante de Seguros |3 - Estipulante |4 - Correspondente de Microsseguros | 5 - Agente de Seguros | 6 - Averbador | 7 - Instituidor  | 99 - Outros
        descricaoIntermediador: "", // preencher caso seja outros
        nomeRazaoSocial: "",
        codigoIntermediador: "", // Obrigatorio e deve ser preenchido com o código previamente cadastrado do corretor em questão
        tipoPessoa: "PJ", // PF ou PJ
        nacionalidade: "S", // S Brasileira | N Extrageira
        cpfCnpj: "",
        valorComissao: "",
        email: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        uf: "",
        municipio: "",
        codigoPostal: "",
        numDocExtrangeiro: "",
        paisResidencia: "1", // 1 Brasil
        observacoes: ""

    }, 
    corretores : [],
    
    criarRegCorretorTxt: (reg) => {
        console.log({corretor: reg});
        var retorno = ""; 
        retorno += reg.funcao + ";";
        retorno += reg.tipoRegistro + ";";
        retorno += reg.acao + ";";
        retorno += reg.tipoItermediador + ";";
        retorno += reg.descricaoIntermediador + ";";
        retorno += reg.nomeRazaoSocial + ";";
        retorno += reg.codigoIntermediador + ";";
        retorno += reg.tipoPessoa + ";";
        retorno += reg.nacionalidade + ";";
        retorno += reg.cpfCnpj + ";";
        retorno += reg.valorComissao + ";";
        retorno += reg.email + ";";
        retorno += reg.logradouro + ";";
        retorno += reg.numero + ";";
        retorno += reg.complemento + ";";
        retorno += reg.bairro + ";";
        retorno += reg.uf + ";";
        retorno += reg.municipio + ";";
        retorno += reg.codigoPostal + ";";
        retorno += reg.numDocExtrangeiro + ";";
        retorno += reg.paisResidencia + ";";
        retorno += reg.observacoes + "";

        return retorno;

    }

}

export const InitialEndosso = {
    endosso: {
        funcao: 'REGEN',
        tipoRegistro: "A",
        acao: "INCL",
        tipoEndosso: "1",
        /**
         *  1 - Sem movimentação de prêmio
            2 - Com acréscimo de prêmio
            3 - Com restituição de prêmio
            4 - Cancelamento de Apólice com restituição de prêmio
            5 - Cancelamento de Endosso com restituição de prêmio
            6 - Cancelamento de Apólice sem restituição de prêmio
            7 - Cancelamento de Endosso sem restituição de prêmio
            8 - Reativação de apólice
            9 - Reativação de endosso
            10 - Averbação (Endosso de Faturamento)
            11 - Averbação (Endosso de Cancelamento de Faturamento)
            12 - Averbação (Endosso de Restituição de Faturamento)
            13 - Averbação (Endosso sem Mov. Prêmio)
            14 - Averbação (Endosso de Cancelamento com restituição)"

         */
        numEndosso: "1",
        dataEmissaoEndosso: "",
        dataInicioVigenciaEndosso: "",
        dataTerminoVigênciaEndosso: "",
        numEndossoAssociado: "",
        motivo: ""
    },
    criarRegEndossoTxt : (reg) => {
        console.log({endosso: reg});
        var retorno = ""; 
        retorno += reg.funcao + ";";
        retorno += reg.tipoRegistro + ";";
        retorno += reg.acao + ";";
        retorno += reg.tipoEndosso + ";";
        retorno += reg.numEndosso + ";";
        retorno += reg.dataEmissaoEndosso + ";";
        retorno += reg.dataInicioVigenciaEndosso + ";";
        retorno += reg.dataTerminoVigênciaEndosso + ";";
        retorno += reg.numEndossoAssociado + ";";
        retorno += reg.motivo + "";

        return retorno;
        
    }
}


export const NOTIFICATIONS = [];



