import { Container, Typography } from '@mui/material';
// sections
import RenewPasswordForm from '../sections/users/renewpassword/RenewPasswordForm';
import ContainerPage from './ContainerPage';
import StyledContent from '../components/styledContent';


export default function RenewPasswordPage() {
  
  return (
    <ContainerPage title="Renovando sua Senha" >
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Renovar sua senha
            </Typography>

            <RenewPasswordForm />
          </StyledContent>
        </Container>
    </ContainerPage>
  );
}
