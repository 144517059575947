import { styled } from "@mui/material";

const StyledContent = styled('div')(({ theme }) => ({
    minWidth: "100%",
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default StyledContent;