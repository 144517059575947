import axios from 'axios';

var adicionalHeaders = [];
//adicionalHeaders['Origin'] = document.location.protocol.concat("//", document.location.host);

export const createClient = async (nameCompany, cpfCnpj, name, email, phone) => {
	const params = {
		nameCompany, cpfCnpj, name, email, phone
	};
    
    console.log({params});

	return axios.post('/clientcreate', { ...params }, {headers: {...adicionalHeaders}}).then((response) => {
        const data = response.data;
        return data.resp;
    }).catch(erro => {
        return erro;
    });
};

