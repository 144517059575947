// @mui
import { Typography, Divider, Link, Container } from '@mui/material';
// sections
import { LoginForm } from '../sections/auth/login';
import ContainerPage from './ContainerPage';
import StyledContent from '../components/styledContent';
import { AppConstants } from '../context/configs/constants';


// ----------------------------------------------------------------------

export default function LoginPage() {

  return (
    <ContainerPage title={"Login"} >

      <Container maxWidth="sm">
        <StyledContent>
          <Typography variant="h4" gutterBottom>
            Acesso ao {AppConstants.nameApp}
          </Typography>
          <Typography variant="h8" gutterBottom>
            Olá, use os campos abaixo para acessar sua conta {AppConstants.nameApp}
          </Typography>
          <Divider sx={{ my: 3 }} />
          <LoginForm />
          <Divider sx={{ my: 3 }} />
          <Typography variant="body2" sx={{ mb: 5 }}>
            Não tem uma conta?  {''}
            <Link variant="subtitle2" href={AppConstants.routePaths.createUser}>Crie a sua</Link>
          </Typography>

        </StyledContent>
      </Container>
    </ContainerPage>
  );
}
