import { Container, Typography } from '@mui/material';
// sections
import { CreateClientForm } from '../sections/clients';
import ContainerPage from './ContainerPage';
import StyledContent from '../components/styledContent';


export default function CreateClientPage() {

  return (
    <ContainerPage title="Crie sua Clinica">
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Informe os dados a seguir!
            </Typography>


            <CreateClientForm />
          </StyledContent>
        </Container>
    </ContainerPage >
  );
}
