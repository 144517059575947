import { Edit } from "@mui/icons-material";
//import { LoadingButton } from "@mui/lab";
import {  Box, Button, Card, CardContent, CircularProgress, Container, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer,  TableRow, TextField, Typography } from "@mui/material";
//import { DateTimePicker } from "@mui/x-date-pickers";
import { AppContext } from "context/AppContext";
import dayjs from 'dayjs';
import { useContext,  useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function ordenarPorApolice(lista, ordem) {
    lista.sort(function (a, b) {
        // Determinando a ordem de classificação com base no parâmetro fornecido
        if (ordem === 'ASC') {
            return a.caracteristicas.codigoSusep.localeCompare(b.caracteristicas.codigoSusep);
        } else if (ordem === 'DESC') {
            return b.caracteristicas.codigoSusep.localeCompare(a.caracteristicas.codigoSusep);
        } else {
            // Se o parâmetro não for válido, retornar sem alterações
            console.error("Parâmetro de ordem inválido. Use 'crescente' ou 'decrescente'.");
            return 0;
        }
    });
    return lista;
}

function ordenarPorProposta(lista, ordem) {
    lista.sort(function (a, b) {
        // Determinando a ordem de classificação com base no parâmetro fornecido
        if (ordem === 'ASC') {
            return a.objeto.codigoOjeto.localeCompare(b.objeto.codigoOjeto);
        } else if (ordem === 'DESC') {
            return b.objeto.codigoOjeto.localeCompare(a.objeto.codigoOjeto);
        } else {
            // Se o parâmetro não for válido, retornar sem alterações
            console.error("Parâmetro de ordem inválido. Use 'crescente' ou 'decrescente'.");
            return 0;
        }
    });
    return lista;
}

function ordenarPorId(lista, ordem) {
    lista.sort(function (a, b) {
        // Determinando a ordem de classificação com base no parâmetro fornecido
        if (ordem === 'ASC') {
            return a.caracteristicas.numeroApolice - b.caracteristicas.numeroApolice;
        } else if (ordem === 'DESC') {
            return b.caracteristicas.numeroApolice - a.caracteristicas.numeroApolice;
        } else {
            // Se o parâmetro não for válido, retornar sem alterações
            console.error("Parâmetro de ordem inválido. Use 'crescente' ou 'decrescente'.");
            return 0;
        }
    });
    return lista;
}


export default function GlobalPage() {
  //const {initialDateFilter, finalDateFilter,  setInitialDateFilter, setFinalDateFilter} = useContext(AppContext);
    const navigate = useNavigate();

    const {BackAppFunctions} = useContext(AppContext);
    const [apolices, setApolices] = useState([]);
    const [apolicesAll, setApolicesAll] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [orderField, setOrderField] = useState("id");
    const [orderType, setOrderType] = useState("ASC");

    const reordenar = (lista, tipo, ordem) => {
        console.log({tipo, ordem, lista});

        switch (tipo) {
            case "id": setApolices(ordenarPorId(lista, ordem));break;
            case "apolice": setApolices(ordenarPorApolice(lista, ordem));break;
            case "proposta": setApolices(ordenarPorProposta(lista, ordem));break;
        
            default:
                break;
        }

    }
    
    const mudarOrderField = async (newOrderField) => {
        setOrderField(newOrderField);
        const lApolices = filterItems ? apolices : apolicesAll;
        reordenar(lApolices, newOrderField, orderType);
    }

    const mudarOrderType = async (newOrderType) => {
        setOrderType(newOrderType);
        const lApolices = filterItems ? apolices : apolicesAll;
        reordenar(lApolices, orderField, newOrderType);
    }




    useEffect(() => {
        //Iniciar com valores default
        BackAppFunctions.ListApolices().then(listApolices => {
            setApolicesAll(listApolices.apolices);
            setApolices((listApolices.apolices));
            });
        // eslint-disable-next-line
    }, []);

    let Real = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    });

    function formatarCPFCNPJ(doc) {
        // Remove caracteres não numéricos
        doc = doc.replace(/\D/g, '');
    
        // Verifica se é CPF (11 dígitos) ou CNPJ (14 dígitos)
        if (doc.length === 11) {
            // Formata CPF
            return doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (doc.length === 14) {
            // Formata CNPJ
            return doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        } else {
            // Retorna mensagem de erro caso não seja CPF nem CNPJ
            return 'Documento inválido';
        }
    }

    const corretores = (apolice) => {
        var resp = "";
        console.log({apoliceCorretor: apolice.corretor});
        console.log({apoliceCorretor: apolice.corretores});
        if ((apolice.corretor !== null) && (apolice.corretor !== undefined)) {
            resp = apolice.corretor.nomeRazaoSocial;
        } else if ((apolice.corretores !== null) && (apolice.corretores !== undefined)) {
            apolice.corretores.map((corretor, i) => {
                if ((apolice.corretor !== null) && (apolice.corretor !== undefined)) {
                    const tam = apolice.corretores.length;
                    if (corretor.nomeRazaoSocial) {
                        resp = resp + corretor.nomeRazaoSocial + ((i + 1) < tam ? " | ": "");
                    } else {
                        //console.log({apoliceError: apolice})
                    }
                }
                
                return resp;
            })
        }

        return resp;
    }


const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    mudarOrderField(orderField);
    filterItems(event.target.value, apolices);
    
};

const filterItems = (searchTerm, lista) => {
    const filtered = lista.filter(item => {
        const obj = JSON.stringify(item);
        const resp = obj.toLowerCase().includes(searchTerm.toLowerCase());
        return resp;
    });
    setApolices(filtered);
};

const editApolice = (apoliceId) => {
    navigate('/dashboard/apoliceedit/'.concat(apoliceId), { replace: true });

}


return (
    <Container maxWidth="xl"  >
        <Grid container spacing={3} style={{height: "100%"}}>
            <Grid item xs={12} sm={4}>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Olá, bem vindo! 
                </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <div>Filtrar:</div>
                        <TextField value={searchTerm} 
                        placeholder="Buscar por nome ou razão social"
                        fullWidth onChange={handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <div>Ordenar por:</div>
                        <Select id="orderField" value={orderField} fullWidth 
                            onChange={(event) => mudarOrderField(event.target.value)} >
                                <MenuItem value={"id"} >ID</MenuItem>
                                <MenuItem value={"apolice"}  >Apólice</MenuItem>
                                <MenuItem value={"proposta"}  >Proposta</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <div>Ordem:</div>
                        <Select id="orderType" value={orderType} fullWidth 
                            onChange={(event) => mudarOrderType(event.target.value)} >
                                <MenuItem value={"ASC"} >Crescente</MenuItem>
                                <MenuItem value={"DESC"}  >Decrescente</MenuItem>
                        </Select>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} style={{height: "100%"}} >
            {(!(apolices.length > 0)) ? <Box display="flex" justifyContent="center"  alignItems="center" style={{height: "100%"}}> <CircularProgress /> </Box> :
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="Apolice List" >
                        <TableBody >
                            {apolices.map((apolice, key) => (
                                
                                <TableRow
                                key={key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell  scope="row" >
                                    <Card variant="outlined"><CardContent>
                                        <Grid container columnSpacing={2} >
                                            <Grid item xs={3}>
                                                ID: <b>{apolice.header.idInterno}</b><br />
                                                Número Documento B3: <b>{apolice.caracteristicas.numeroApolice}</b>
                                            </Grid>
                                            <Grid item xs={3}>
                                                Proposta: <br /><b>{apolice.objeto.codigoOjeto}</b>
                                            </Grid>
                                            <Grid item xs={3}>
                                                Apolice: <br /><b>{apolice.caracteristicas.codigoSusep}</b>
                                            </Grid>
                                            <Grid item xs={3}  >
                                                <Box display="flex" justifyContent="flex-end">
                                                <Button variant="contained" startIcon={<Edit />} 
                                                        onClick={() => editApolice(apolice.header.idInterno)}>
                                                            Detalhes da Apolice
                                                </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}  style={{height: 20}} ></Grid>

                                            <Grid item xs={8}>
                                                <Grid container spacing={{xs: 1}}>
                                                    <Grid item xs={3}><Box display="flex" justifyContent="flex-end">Tomador:</Box></Grid>
                                                    <Grid item xs={9}><b>{apolice.tomador.nomeRazaoSocial}</b></Grid>
                                                    <Grid item xs={3}></Grid>
                                                    <Grid item xs={9}>{formatarCPFCNPJ(apolice.tomador.cpfCnpj)}</Grid>
                                                    <Grid item xs={3}><Box display="flex" justifyContent="flex-end">Segurado:</Box></Grid>
                                                    <Grid item xs={9}><b>{apolice.segurado.nomeRazaoSocial}</b></Grid>
                                                    <Grid item xs={3}></Grid>
                                                    <Grid item xs={9}>{formatarCPFCNPJ(apolice.segurado.cpfCnpj)}</Grid>
                                                    <Grid item xs={3}><Box display="flex" justifyContent="flex-end">Corretor(es):</Box></Grid>
                                                    <Grid item xs={9}><b>{corretores(apolice)}</b></Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Card style={{backgroundColor: "#f0f0f0"}}><CardContent>
                                                <Grid container>
                                                    <Grid item xs={4}>Modalidade: </Grid>
                                                    <Grid item xs={8}><Box display="flex" justifyContent="flex-end"><b>Prestador de Serviços </b></Box></Grid>
                                                    <Grid item xs={4}>Garantia:</Grid>
                                                    <Grid item xs={8}><Box display="flex" justifyContent="flex-end"><b>{Real.format(apolice.caracteristicas.limiteMaximoGarantia)}</b></Box></Grid>
                                                    <Grid item xs={4}>Prêmio:</Grid>
                                                    <Grid item xs={8}><Box display="flex" justifyContent="flex-end"> <b>{Real.format(apolice.caracteristicas.valorTotalPremio)}</b></Box></Grid>
                                                    <Grid item xs={4}>Emissão:</Grid>
                                                    <Grid item xs={8}><Box display="flex" justifyContent="flex-end"> <b>{dayjs(apolice.caracteristicas.dataEmissao).format("DD/MM/YYYY")}</b></Box></Grid>
                                                    <Grid item xs={4}>Vigencia:</Grid>
                                                    <Grid item xs={8}><Box display="flex" justifyContent="flex-end"> <b>{dayjs(apolice.caracteristicas.dataInicioVigencia).format("DD/MM/YYYY")} a {dayjs(apolice.caracteristicas.dataTerminoVigencia).format("DD/MM/YYYY")}</b></Box></Grid>
                                                </Grid>
                                                </CardContent></Card>
                                                
                                            </Grid>
                                            <Grid item xs={12}  style={{height: 20}} ></Grid>
                                            <Grid item xs={12}>
                                            <Box display="flex" justifyContent="flex-end">
                                               {/*** <Button variant="contained" color="success" size="small">Status: Transmitida</Button> */}
                                            </Box>
                                            </Grid>

                                        </Grid>
                                    </CardContent></Card>
                                </TableCell>

                                </TableRow>
                                ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            }


            </Grid>
        </Grid>

        


    </Container>
  );
}

