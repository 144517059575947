
export default function navigateMenu(obj, navigate) {
    
    if (obj.type === "link") {
      window.open(obj.url, '_self');
    } else if (obj.type === "navigate") {
      navigate(obj.url);
    } else {
      console.log('type link undefined');
    }
    
  }

